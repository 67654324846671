var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";
import { Box, IconButton } from "@mui/joy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
var WaveformPlayer = function (_a) {
    var audioUrl = _a.audioUrl;
    var waveformRef = useRef(null);
    var wavesurferRef = useRef(null);
    // Track loading, playback, and timing
    var _b = useState(false), isLoaded = _b[0], setIsLoaded = _b[1];
    var _c = useState(false), isPlaying = _c[0], setIsPlaying = _c[1];
    // Initialize WaveSurfer on mount
    useEffect(function () {
        if (!waveformRef.current)
            return;
        // Create the timeline plugin instance:
        var timeline = TimelinePlugin.create({
            height: 15
        });
        // Create WaveSurfer instance
        wavesurferRef.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: "#ccc",
            progressColor: "#ff9800",
            cursorColor: "#f44336",
            autoScroll: true,
            fillParent: true,
            hideScrollbar: false,
            height: 60,
            // Split stereo channels with different colors
            splitChannels: [
                {
                    waveColor: "#ffc107",
                    progressColor: "#ff9800",
                    overlay: true,
                },
                {
                    waveColor: "#03a9f4",
                    progressColor: "#0288d1",
                    overlay: true,
                },
            ],
            // Attach the timeline as a plugin
            plugins: [timeline],
            // Load the audio file
            url: audioUrl,
        });
        // WaveSurfer event listeners
        wavesurferRef.current.on("ready", function () {
            setIsLoaded(true);
        });
        wavesurferRef.current.on("finish", function () {
            setIsPlaying(false);
        });
        wavesurferRef.current.on("error", function (e) {
            console.error("WaveSurfer load error:", e);
        });
        // Cleanup on unmount
        return function () {
            var _a;
            try {
                (_a = wavesurferRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
            }
            catch (error) {
                console.warn("WaveSurfer destruction failed:", error);
            }
        };
    }, [audioUrl]);
    var handlePlayPause = function () {
        if (!isLoaded)
            return;
        if (wavesurferRef.current) {
            if (wavesurferRef.current.isPlaying()) {
                wavesurferRef.current.pause();
                setIsPlaying(false);
            }
            else {
                wavesurferRef.current.play();
                setIsPlaying(true);
            }
        }
    };
    return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", gap: 2 } }, { children: [_jsx(IconButton, __assign({ size: "sm", variant: "plain", onClick: handlePlayPause, disabled: !isLoaded, sx: { zIndex: 2 } }, { children: isPlaying ? _jsx(PauseIcon, {}) : _jsx(PlayArrowIcon, {}) })), _jsx(Box, { ref: waveformRef, sx: { width: "400px" } })] })));
};
export default WaveformPlayer;
