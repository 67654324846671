var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRecoilState } from 'recoil';
import { workspaceConversationsDashboardStatsAtom, workspaceConversationCommonTopicsStatsAtom, getWorkspaceConversationCommonTopicsStatsIsLoadingAtom, workspaceConversationKnowledgeBaseGapsStatsAtom, workspaceConversationEscalatedStatsAtom, workspaceConversationSentimentStatsAtom, workspaceConversationSessionsStatsAtom, workspaceConversationsSentimentByCommonTopicsStatsAtom, getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoadingAtom, workspaceConversationSessionsBySentimentFilteredAtom, chatConversationTrendingChartDataAtom, isLoadingChatConversationTrendingChartDataAtom } from '../store';
import { dateFilterValuesGenerator, generateQueryParams } from '../../utils';
import { useRequest } from '../../hooks/useRequest';
import { FilterEnums } from '../../types/enums';
export var useDashboard = function () {
    var request = useRequest();
    var _a = useRecoilState(workspaceConversationsDashboardStatsAtom), workspaceConversationsDashboardStats = _a[0], setWorkspaceConversationsDashboardStats = _a[1];
    var _b = useRecoilState(workspaceConversationCommonTopicsStatsAtom), workspaceConversationsCommonTopicsStats = _b[0], setWorkspaceConversationCommonTopicsStats = _b[1];
    var _c = useRecoilState(getWorkspaceConversationCommonTopicsStatsIsLoadingAtom), isWorkspaceConversationCommonTopicsStatsIsLoading = _c[0], setIsWorkspaceConversationCommonTopicsStatsIsLoading = _c[1];
    var _d = useRecoilState(workspaceConversationKnowledgeBaseGapsStatsAtom), workspaceConversationKnowledgeBaseGapsStats = _d[0], setWorkspaceConversationKnowledgeBaseGapsStats = _d[1];
    var _e = useRecoilState(workspaceConversationEscalatedStatsAtom), workspaceConversationEscalatedStats = _e[0], setWorkspaceConversationEscalatedStats = _e[1];
    var _f = useRecoilState(workspaceConversationSentimentStatsAtom), workspaceConversationSentimentStats = _f[0], setWorkspaceConversationSentimentStats = _f[1];
    var _g = useRecoilState(workspaceConversationSessionsStatsAtom), workspaceConversationSessionsStats = _g[0], setWorkspaceConversationSessionsStats = _g[1];
    var _h = useRecoilState(workspaceConversationsSentimentByCommonTopicsStatsAtom), workspaceConversationsSentimentByCommonTopicsStats = _h[0], setWorkspaceConversationsSentimentByCommonTopicsStats = _h[1];
    var _j = useRecoilState(getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoadingAtom), isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading = _j[0], setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading = _j[1];
    var _k = useRecoilState(workspaceConversationSessionsBySentimentFilteredAtom), workspaceConversationSessionsBySentimentFiltered = _k[0], setWorkspaceConversationSessionsBySentimentFiltered = _k[1];
    var _l = useRecoilState(chatConversationTrendingChartDataAtom), conversationTrendingChartData = _l[0], setConversationTrendingChartData = _l[1];
    var _m = useRecoilState(isLoadingChatConversationTrendingChartDataAtom), isLoadingConversationTrendingChartData = _m[0], setIsLoadingConversationTrendingChartData = _m[1];
    /** Example helper to build the date range query params from filter, custom dates */
    var buildDateParams = function (filter, customStartDate, customEndDate) {
        var _a = dateFilterValuesGenerator(filter, customStartDate, customEndDate), startDate = _a.startDate, endDate = _a.endDate;
        return generateQueryParams({ startDate: startDate, endDate: endDate });
    };
    // 1) Dashboard Stats
    var getWorkspaceConversationsDashboardStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationsDashboardStats?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationsDashboardStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 2) Common Topics
    var getWorkspaceConversationsCommonTopicsStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsWorkspaceConversationCommonTopicsStatsIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationCommonTopicsStats?".concat(queryParams))];
                case 2:
                    data = (_a.sent()).data;
                    setWorkspaceConversationCommonTopicsStats(data);
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsWorkspaceConversationCommonTopicsStatsIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // 3) Knowledge Base Gaps
    var getWorkspaceConversationKnowledgeBaseGapsStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationKnowledgeBaseGapsStats?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationKnowledgeBaseGapsStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error(e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 4) Escalations
    var getWorkspaceEscalatedConversationStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationEscalatedStats?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationEscalatedStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    console.error(e_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 5) Sentiment
    var getWorkspaceConversationSentimentStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationSentimentStats?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationSentimentStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    console.error(e_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 6) Sessions
    var getWorkspaceConversationSessionsStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationSessionStats?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationSessionsStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    console.error(e_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 7) Sentiment By Common Topics
    var getWorkspaceConversationsSentimentByCommonTopicsStats = function (filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    queryParams = buildDateParams(filter, customStartDate, customEndDate);
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationSentimentsByCommonTopicsStats?".concat(queryParams))];
                case 2:
                    data = (_a.sent()).data;
                    setWorkspaceConversationsSentimentByCommonTopicsStats(data);
                    return [3 /*break*/, 5];
                case 3:
                    e_7 = _a.sent();
                    console.error(e_7);
                    return [3 /*break*/, 5];
                case 4:
                    setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // 8) Sessions By Sentiment Filter
    var getWorkspaceConversationSessionsBySentimentFiltered = function (sentiment, topic, filter, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var dateParams, queryParams, data, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dateParams = buildDateParams(filter !== null && filter !== void 0 ? filter : FilterEnums.all, customStartDate, customEndDate);
                    queryParams = generateQueryParams(__assign(__assign({}, Object.fromEntries(new URLSearchParams(dateParams))), { sentiment: sentiment, topic: topic }));
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversationSessionsBySentimentFiltered?".concat(queryParams))];
                case 1:
                    data = (_a.sent()).data;
                    setWorkspaceConversationSessionsStats(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_8 = _a.sent();
                    console.error(e_8);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getConversationTrendingChartData = function (filter, grouping, flowId, customStartDate, customEndDate) { return __awaiter(void 0, void 0, void 0, function () {
        var timeZone, dateFilter, queryParams, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoadingConversationTrendingChartData(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    dateFilter = dateFilterValuesGenerator(filter, customStartDate, customEndDate);
                    queryParams = generateQueryParams(__assign(__assign({}, dateFilter), { // has { startDate, endDate }
                        grouping: grouping, // "daily", "monthly", or "hourly"
                        timeZone: timeZone, flowId: flowId }));
                    return [4 /*yield*/, request.get("/ConversationAnalytics/workspaceConversation/trending?".concat(queryParams))];
                case 2:
                    response = _a.sent();
                    // 5) Store in Recoil (or local state)
                    setConversationTrendingChartData(response.data); // Should match the shape: { labels, datasets }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to fetch conversation trending chart data:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoadingConversationTrendingChartData(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return {
        // atoms
        workspaceConversationsDashboardStats: workspaceConversationsDashboardStats,
        workspaceConversationsCommonTopicsStats: workspaceConversationsCommonTopicsStats,
        isWorkspaceConversationCommonTopicsStatsIsLoading: isWorkspaceConversationCommonTopicsStatsIsLoading,
        workspaceConversationKnowledgeBaseGapsStats: workspaceConversationKnowledgeBaseGapsStats,
        workspaceConversationEscalatedStats: workspaceConversationEscalatedStats,
        workspaceConversationSentimentStats: workspaceConversationSentimentStats,
        workspaceConversationSessionsStats: workspaceConversationSessionsStats,
        workspaceConversationsSentimentByCommonTopicsStats: workspaceConversationsSentimentByCommonTopicsStats,
        isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading: isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading,
        workspaceConversationSessionsBySentimentFiltered: workspaceConversationSessionsBySentimentFiltered,
        conversationTrendingChartData: conversationTrendingChartData,
        isLoadingConversationTrendingChartData: isLoadingConversationTrendingChartData,
        // methods
        getWorkspaceConversationsDashboardStats: getWorkspaceConversationsDashboardStats,
        getWorkspaceConversationsCommonTopicsStats: getWorkspaceConversationsCommonTopicsStats,
        getWorkspaceConversationKnowledgeBaseGapsStats: getWorkspaceConversationKnowledgeBaseGapsStats,
        getWorkspaceEscalatedConversationStats: getWorkspaceEscalatedConversationStats,
        getWorkspaceConversationSentimentStats: getWorkspaceConversationSentimentStats,
        getWorkspaceConversationSessionsStats: getWorkspaceConversationSessionsStats,
        getWorkspaceConversationsSentimentByCommonTopicsStats: getWorkspaceConversationsSentimentByCommonTopicsStats,
        getWorkspaceConversationSessionsBySentimentFiltered: getWorkspaceConversationSessionsBySentimentFiltered,
        getConversationTrendingChartData: getConversationTrendingChartData,
    };
};
