var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/CallFlows/components/FlowCanvas/nodes/BuyerNode.tsx
import { Handle, Position } from "@xyflow/react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import { Box, Select, Option } from "@mui/joy";
export default function BuyerNode(_a) {
    var id = _a.id, data = _a.data;
    var buyerId = data.buyerId, buyers = data.buyers, onBuyerChange = data.onBuyerChange;
    var handleChange = function (newBuyerId) {
        onBuyerChange === null || onBuyerChange === void 0 ? void 0 : onBuyerChange(id, newBuyerId);
    };
    return (_jsxs(Card, __assign({ variant: "soft", color: "danger", sx: { minWidth: 200, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(PaidRoundedIcon, {}) }, { children: "Buyer" })), _jsx(Box, __assign({ mt: 1 }, { children: _jsx(Select, __assign({ size: "sm", value: buyerId, onChange: function (_, val) {
                        if (val)
                            handleChange(Number(val));
                    }, placeholder: "Select buyer" }, { children: buyers === null || buyers === void 0 ? void 0 : buyers.map(function (buyer) { return (_jsx(Option, __assign({ value: buyer.id }, { children: buyer.name }), buyer.id)); }) })) })), _jsx(Handle, { style: {
                    width: 8,
                    height: 8,
                }, type: "target", position: Position.Top })] })));
}
