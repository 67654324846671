// src/pages/CallFlows/components/FlowCanvas/nodeTypes.ts
var _a;
import InboundCallNode from "./nodes/InboundCallNode";
import RouterNode from "./nodes/RouterNode";
import AIAgentNode from "./nodes/AIAgentNode";
import BuyerNode from "./nodes/BuyerNode";
import BuyerRouterNode from "./nodes/BuyerRouterNode";
import { NODE_NAMES } from "./utils/nodeNameContants";
/**
 * Registry of custom node types.
 */
export var nodeTypes = (_a = {},
    _a[NODE_NAMES.INBOUND_CALL] = InboundCallNode,
    _a[NODE_NAMES.CALL_DISTRIBUTION_ROUTER] = RouterNode,
    _a[NODE_NAMES.AI_AGENT] = AIAgentNode,
    _a[NODE_NAMES.BUYER_ROUTER] = BuyerRouterNode,
    _a[NODE_NAMES.BUYER] = BuyerNode,
    _a);
