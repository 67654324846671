var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position } from "@xyflow/react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box } from "@mui/joy";
export default function InboundCallNode(_a) {
    var id = _a.id, data = _a.data;
    var phoneNumbers = data.phoneNumbers;
    return (_jsxs(Card, __assign({ variant: "soft", color: "primary", sx: { minWidth: 200, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(PhoneIcon, {}) }, { children: "Inbound Call" })), _jsx(Box, __assign({ mt: 1 }, { children: phoneNumbers === null || phoneNumbers === void 0 ? void 0 : phoneNumbers.map(function (num) { return (_jsx(Typography, __assign({ level: "body-sm" }, { children: num.friendlyName }), num.id)); }) })), _jsx(Handle, { style: {
                    width: 8,
                    height: 8,
                }, type: "source", position: Position.Bottom })] })));
}
