var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Snackbar from "@mui/joy/Snackbar";
import Button from "@mui/joy/Button";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
var NotificationSnackbars = function (_a) {
    var errorMessage = _a.errorMessage, successMessage = _a.successMessage, setErrorMessage = _a.setErrorMessage, setSuccessMessage = _a.setSuccessMessage;
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, __assign({ variant: "soft", color: "danger", open: Boolean(errorMessage), onClose: function () { return setErrorMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(ErrorIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setErrorMessage(null); }, size: "sm", variant: "soft", color: "danger" }, { children: "Dismiss" })) }, { children: errorMessage })), _jsx(Snackbar, __assign({ variant: "soft", color: "success", open: Boolean(successMessage), onClose: function () { return setSuccessMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(DoneIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setSuccessMessage(null); }, size: "sm", variant: "soft", color: "success" }, { children: "Dismiss" })) }, { children: successMessage }))] }));
};
export default NotificationSnackbars;
