var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { FormControl, FormLabel, Select, Option, Input, Checkbox, Box, Stack, } from "@mui/joy";
import TimePicker from "react-time-picker";
import "../../../styles/timePickerStyles.css";
export var HangfireCronBuilder = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    // States for cron fields
    var _b = useState("daily"), frequency = _b[0], setFrequency = _b[1];
    var _c = useState(17), hour = _c[0], setHour = _c[1];
    var _d = useState(30), minute = _d[0], setMinute = _d[1];
    var _e = useState([0, 1]), daysOfWeek = _e[0], setDaysOfWeek = _e[1];
    var _f = useState(1), dayOfMonth = _f[0], setDayOfMonth = _f[1];
    // Day options (Hangfire uses Sunday=0, Monday=1,..., Saturday=6)
    var dayOptions = [
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 },
    ];
    /** Parse external CRON expression and set state */
    useEffect(function () {
        if (value) {
            var parts = value.split(" ");
            if (parts.length === 5) {
                var mm = parts[0], hh = parts[1], dom = parts[2], dow = parts[4];
                var utcDate = new Date();
                utcDate.setUTCHours(parseInt(hh, 10));
                utcDate.setUTCMinutes(parseInt(mm, 10));
                // Convert to local time
                var localHour = utcDate.getHours();
                var localMinute = utcDate.getMinutes();
                setHour(localHour);
                setMinute(localMinute);
                if (dow !== "*") {
                    setFrequency("weekly");
                    setDaysOfWeek(dow.split(",").map(Number));
                }
                else if (dom !== "*") {
                    setFrequency("monthly");
                    setDayOfMonth(parseInt(dom, 10));
                }
                else {
                    setFrequency("daily");
                }
            }
        }
    }, []);
    // Convert local input back to UTC cron expression
    var buildCronExpression = function () {
        var localDate = new Date();
        localDate.setHours(hour, minute, 0, 0);
        var utcHour = localDate.getUTCHours();
        var utcMinute = localDate.getUTCMinutes();
        switch (frequency) {
            case "daily":
                return "".concat(utcMinute, " ").concat(utcHour, " * * *");
            case "weekly": {
                var dowPart = daysOfWeek.length ? daysOfWeek.join(",") : "0";
                return "".concat(utcMinute, " ").concat(utcHour, " * * ").concat(dowPart);
            }
            case "monthly":
                return "".concat(utcMinute, " ").concat(utcHour, " ").concat(Math.max(1, Math.min(31, dayOfMonth)), " * *");
            default:
                return "".concat(utcMinute, " ").concat(utcHour, " * * *");
        }
    };
    /** Update parent component whenever values change */
    useEffect(function () {
        onChange(buildCronExpression());
    }, [frequency, hour, minute, daysOfWeek, dayOfMonth]);
    /** Toggle day of week */
    var toggleDayOfWeek = function (value) {
        setDaysOfWeek(function (prev) {
            return prev.includes(value) ? prev.filter(function (d) { return d !== value; }) : __spreadArray(__spreadArray([], prev, true), [value], false);
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Frequency" }), _jsxs(Select, __assign({ size: "sm", value: frequency, onChange: function (_, val) { return setFrequency(val); } }, { children: [_jsx(Option, __assign({ value: "daily" }, { children: "Daily" })), _jsx(Option, __assign({ value: "weekly" }, { children: "Weekly" })), _jsx(Option, __assign({ value: "monthly" }, { children: "Monthly" }))] }))] }), _jsx(Stack, __assign({ direction: "row", spacing: 2 }, { children: _jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Time" }), _jsx(TimePicker, { onChange: function (newTime) {
                                    if (newTime) {
                                        var _a = newTime.split(" "), time = _a[0], period = _a[1];
                                        var _b = time.split(":"), hh = _b[0], mm = _b[1];
                                        var hour_1 = parseInt(hh, 10);
                                        var minute_1 = parseInt(mm, 10);
                                        // Convert to 24-hour format
                                        if (period === "PM" && hour_1 !== 12) {
                                            hour_1 += 12;
                                        }
                                        else if (period === "AM" && hour_1 === 12) {
                                            hour_1 = 0;
                                        }
                                        setHour(hour_1);
                                        setMinute(minute_1);
                                    }
                                }, amPmAriaLabel: "Select AM/PM", value: "".concat(hour, ":").concat(minute), className: "custom-time-picker", clearIcon: null })] }) })), frequency === "weekly" && (_jsxs(Box, { children: [_jsx(FormLabel, { children: "Days of Week" }), _jsx(Stack, __assign({ direction: "row", flexWrap: "wrap", gap: 2, mt: 1 }, { children: dayOptions.map(function (opt) { return (_jsx(Checkbox, { size: "sm", label: opt.label, checked: daysOfWeek.includes(opt.value), onChange: function () { return toggleDayOfWeek(opt.value); } }, opt.value)); }) }))] })), frequency === "monthly" && (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Day of Month (1-31)" }), _jsx(Input, { type: "number", size: "sm", slotProps: {
                                input: {
                                    min: 1,
                                    max: 31,
                                    step: 1,
                                },
                            }, value: dayOfMonth, onChange: function (e) { return setDayOfMonth(Number(e.target.value)); }, sx: { width: 80 } })] }))] })) }));
};
