// parseDetailedLogs.ts
/** Format a Date to "HH:mm:ss.sss" (24-hour) */
function formatTime(date) {
    return (date.toLocaleTimeString("en-US", { hour12: false }) +
        "." +
        String(date.getMilliseconds()).padStart(3, "0"));
}
export function parseDetailedLogs(logs) {
    // 1) Group logs by conversationItemId
    var byConversationId = {};
    for (var _i = 0, logs_1 = logs; _i < logs_1.length; _i++) {
        var log = logs_1[_i];
        var cid = log.message.conversationItemId;
        if (!byConversationId[cid])
            byConversationId[cid] = [];
        byConversationId[cid].push(log);
    }
    // We'll accumulate all rows for all conversation IDs
    var allRows = [];
    // 2) For each conversation item, parse logs + gather latencies
    for (var _a = 0, _b = Object.keys(byConversationId); _a < _b.length; _a++) {
        var cid = _b[_a];
        var group = byConversationId[cid];
        // Sort logs by ascending time
        group.sort(function (a, b) { return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(); });
        // We'll store normal lines in groupRows
        var groupRows = [];
        // We'll track relevant times in l
        var l = {};
        for (var _c = 0, group_1 = group; _c < group_1.length; _c++) {
            var log = group_1[_c];
            var dt = new Date(log.timestamp);
            var timeString = formatTime(dt);
            var text = log.message.message || "";
            // e.g. "[CHECKPOINT]", "[LOG]", etc.
            var logType = "OTHER";
            var bracketMatch = text.match(/^\[(.*?)\]/);
            if (bracketMatch) {
                logType = bracketMatch[1].toUpperCase(); // => "CHECKPOINT", "LOG", ...
            }
            // Clean the bracket portion: "[CHECKPOINT] foo" => "foo"
            var cleaned = text.replace(/^\[.*?\]\s*/, "").trim();
            // Capture relevant timestamps if it’s a checkpoint
            if (logType === "CHECKPOINT") {
                if (text.includes("User speech possibly starting")) {
                    l.userSpeechStart = dt;
                }
                else if (text.includes("Transcriber Output:")) {
                    l.transcriberOutput = dt;
                }
                else if (text.match(/model request started/i)) {
                    l.modelRequestStart = dt;
                }
                else if (text.includes("Model recieved first output token")) {
                    l.modelFirstToken = dt;
                }
                else if (text.includes("Model send first output sentence To TTS")) {
                    l.modelSendToTTS = dt;
                }
                else if (text.includes("Assistant speech started")) {
                    l.assistantSpeechStart = dt;
                }
            }
            // Add a row for this line
            groupRows.push({
                timestamp: dt,
                timeString: timeString,
                logType: logType,
                message: cleaned,
            });
        }
        // 3) Once we have that group's lines, compute latencies
        //   - Transcription
        //   - Model
        //   - TTS (voice)
        var transMs = void 0;
        var modelMs = void 0;
        var ttsMs = void 0;
        // Transcription = transcriberOutput - userSpeechStart
        if (l.userSpeechStart && l.transcriberOutput) {
            var val = l.transcriberOutput.getTime() - l.userSpeechStart.getTime();
            transMs = Math.max(val, 0);
        }
        // Model = modelFirstToken - modelRequestStart
        if (l.modelRequestStart && l.modelFirstToken) {
            var val = l.modelFirstToken.getTime() - l.modelRequestStart.getTime();
            modelMs = Math.max(val, 0);
        }
        // TTS = assistantSpeechStart - modelSendToTTS
        //  (the user specifically wants this from "Model send first output sentence To TTS"
        //   to "Assistant speech started")
        if (l.modelSendToTTS && l.assistantSpeechStart) {
            var val = l.assistantSpeechStart.getTime() - l.modelSendToTTS.getTime();
            ttsMs = Math.max(val, 0);
        }
        // If we have at least one latency, insert an extra row at the end
        if (transMs !== undefined || modelMs !== undefined || ttsMs !== undefined) {
            // We'll timestamp it as the last line in the group
            var finalTimestamp = groupRows.length > 0
                ? groupRows[groupRows.length - 1].timestamp
                : new Date();
            var timeString = formatTime(finalTimestamp);
            var partials = [];
            if (transMs !== undefined)
                partials.push("transcriber: ".concat(transMs, "ms"));
            if (modelMs !== undefined)
                partials.push("model: ".concat(modelMs, "ms"));
            if (ttsMs !== undefined)
                partials.push("voice: ".concat(ttsMs, "ms"));
            var sum = (transMs || 0) + (modelMs || 0) + (ttsMs || 0);
            var turnMessage = "Turn latency: ".concat(sum, "ms (").concat(partials.join(", "), ")");
            groupRows.push({
                timestamp: finalTimestamp,
                timeString: timeString,
                logType: "INFO",
                message: turnMessage,
                transcriberMs: transMs,
                modelMs: modelMs,
                ttsMs: ttsMs,
            });
        }
        // Accumulate into allRows
        allRows.push.apply(allRows, groupRows);
    }
    // 4) Finally, re-sort across *all conversation items* by ascending timestamp,
    //    so it’s a single timeline.
    allRows.sort(function (a, b) { return a.timestamp.getTime() - b.timestamp.getTime(); });
    // 5) Compute deltaMs for each row => how many ms since the previous line in final timeline
    var prevTime = allRows.length > 0 ? allRows[0].timestamp : null;
    for (var i = 1; i < allRows.length; i++) {
        var current = allRows[i];
        if (prevTime) {
            var delta = current.timestamp.getTime() - prevTime.getTime();
            // Store in .deltaMs
            current.deltaMs = Math.max(delta, 0);
        }
        prevTime = current.timestamp;
    }
    return allRows;
}
