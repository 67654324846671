import { nodeConnectionRules } from "../configs/nodeConnectionRules";
/**
 * Check if source -> target is permitted
 * under the nodeConnectionRules plus max in/out constraints.
 */
export function isConnectionAllowed(sourceNode, targetNode, edges) {
    if (!sourceNode || !targetNode) {
        return { allowed: false, reason: "One or both nodes are missing." };
    }
    if (!sourceNode.type || !targetNode.type) {
        return { allowed: false, reason: "One or both nodes have an undefined type." };
    }
    var sourceRules = nodeConnectionRules[sourceNode.type];
    var targetRules = nodeConnectionRules[targetNode.type];
    // Check if the node type exists in the rules
    if (!sourceRules) {
        return { allowed: false, reason: "Invalid source node type: ".concat(sourceNode.type, ".") };
    }
    if (!targetRules) {
        return { allowed: false, reason: "Invalid target node type: ".concat(targetNode.type, ".") };
    }
    // 1) Check if the target node type is in the source node's allowedOutgoingNodes
    if (!sourceRules.allowedOutgoingNodes.includes(targetNode.type)) {
        return {
            allowed: false,
            reason: "".concat(sourceNode.type, " is not allowed to connect to ").concat(targetNode.type, "."),
        };
    }
    // 2) Check if the source node type is in the target node's allowedIncomingNodes
    if (!targetRules.allowedIncomingNodes.includes(sourceNode.type)) {
        return {
            allowed: false,
            reason: "".concat(targetNode.type, " cannot accept a connection from ").concat(sourceNode.type, "."),
        };
    }
    // 3) Check maxOutgoing limit for the source node
    var existingOutgoingEdges = edges.filter(function (e) { return e.source === sourceNode.id; }).length;
    if (existingOutgoingEdges >= sourceRules.maxOutgoing) {
        return {
            allowed: false,
            reason: "".concat(sourceNode.type, " can have a maximum of ").concat(sourceRules.maxOutgoing, " outgoing connection(s)."),
        };
    }
    // 4) Check maxIncoming limit for the target node
    var existingIncomingEdges = edges.filter(function (e) { return e.target === targetNode.id; }).length;
    if (existingIncomingEdges >= targetRules.maxIncoming) {
        return {
            allowed: false,
            reason: "".concat(targetNode.type, " can have a maximum of ").concat(targetRules.maxIncoming, " incoming connection(s)."),
        };
    }
    return { allowed: true, reason: "Connection allowed." };
}
