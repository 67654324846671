var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//src\pages\CallAnalytics\PerformanceCards\index.tsx
import { Card, CardContent, Typography, Stack } from "@mui/joy";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
// Helper function to render percentage with performance icons
var renderPercentage = function (percentChange) { return (_jsxs(Typography, __assign({ level: "body-sm", sx: {
        color: percentChange > 0 ? "success.600" : "danger.600",
        display: "flex",
        alignItems: "center",
    } }, { children: [percentChange > 0 ? (_jsx(TrendingUpIcon, { fontSize: "small", sx: { mr: 0.2 } })) : (_jsx(TrendingDownIcon, { fontSize: "small", sx: { mr: 0.2 } })), "".concat(percentChange.toFixed(1), "%")] }))); };
// Main Component
export default function PerformanceCards(_a) {
    var _b, _c, _d, _e, _f, _g;
    var cardMetrics = _a.cardMetrics;
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ direction: "row", sx: { flexWrap: "wrap", gap: 2 } }, { children: [_jsx(Card, __assign({ variant: "outlined", sx: { minWidth: 220, maxWidth: 220, borderRadius: "12px", boxShadow: "sm" } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "body-md", sx: { mb: 0.5 } }, { children: "Call Count" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(Typography, __assign({ level: "h2" }, { children: (_b = cardMetrics.callCount) !== null && _b !== void 0 ? _b : 0 })), renderPercentage((_c = cardMetrics.callCountPercentChange) !== null && _c !== void 0 ? _c : 0)] }))] }) })), _jsx(Card, __assign({ variant: "outlined", sx: { minWidth: 220, maxWidth: 220, borderRadius: "12px", boxShadow: "sm" } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "body-md", sx: { mb: 0.5 } }, { children: "Total Call Duration" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(Typography, __assign({ level: "h2" }, { children: (_e = (_d = cardMetrics.totalDurationInMinutes) === null || _d === void 0 ? void 0 : _d.toFixed(1)) !== null && _e !== void 0 ? _e : 0 })), renderPercentage((_f = cardMetrics.totalDurationInMinutesPercentChange) !== null && _f !== void 0 ? _f : 0)] }))] }) }))] })), ((_g = cardMetrics === null || cardMetrics === void 0 ? void 0 : cardMetrics.tags) === null || _g === void 0 ? void 0 : _g.length) > 0 && (_jsx(Stack, __assign({ direction: "row", gap: 2, sx: { mt: 2 }, flexWrap: "wrap" }, { children: cardMetrics.tags.map(function (tagObj, idx) {
                    var _a;
                    return (_jsx(Card, __assign({ variant: "outlined", sx: {
                            minWidth: 220,
                            maxWidth: 220,
                            borderRadius: "12px",
                            boxShadow: "sm",
                        } }, { children: _jsxs(CardContent, { children: [_jsxs(Typography, __assign({ level: "body-md", sx: { mb: 0.5 } }, { children: ["Tag: ", tagObj.tag] })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(Typography, __assign({ level: "h2" }, { children: tagObj.count })), renderPercentage((_a = tagObj.percentChange) !== null && _a !== void 0 ? _a : 0)] }))] }) }), idx));
                }) })))] }));
}
