// src/data/hooks/useBuyers.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRecoilState } from "recoil";
import { buyersAtom } from "../store";
import { useRequest } from "../../hooks/useRequest";
export var useBuyers = function () {
    var _a = useRecoilState(buyersAtom), buyers = _a[0], setBuyers = _a[1];
    var request = useRequest();
    var getAllBuyers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.get("/Buyer")];
                case 1:
                    data = (_a.sent()).data;
                    setBuyers(data);
                    return [2 /*return*/, data];
                case 2:
                    error_1 = _a.sent();
                    console.error("Failed to fetch buyers:", error_1);
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getBuyerById = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.get("/Buyer/".concat(id))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_2 = _a.sent();
                    console.error("Failed to fetch buyer with ID ".concat(id, ":"), error_2);
                    throw error_2;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var createBuyer = function (buyer) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.post("/Buyer", buyer)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_3 = _a.sent();
                    console.error("Failed to create buyer:", error_3);
                    throw error_3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateBuyer = function (id, buyer) { return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.put("/Buyer/".concat(id), buyer)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    console.error("Failed to update buyer #".concat(id, ":"), error_4);
                    throw error_4;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var deleteBuyer = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.delete("/Buyer/".concat(id))];
                case 1:
                    _a.sent();
                    // Optionally remove from Recoil or refresh
                    setBuyers(function (prev) { return prev.filter(function (b) { return b.id !== id; }); });
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    console.error("Failed to delete buyer #".concat(id, ":"), error_5);
                    throw error_5;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return {
        buyers: buyers,
        setBuyers: setBuyers,
        getAllBuyers: getAllBuyers,
        getBuyerById: getBuyerById,
        createBuyer: createBuyer,
        updateBuyer: updateBuyer,
        deleteBuyer: deleteBuyer,
    };
};
