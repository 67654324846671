var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Box, IconButton } from "@mui/joy";
import DownloadIcon from "@mui/icons-material/Download";
import WaveformPlayer from "../../../../../components/ui/WaveformPlayer";
export default function MessagesPaneHeader(props) {
    var sender = props.sender, recordingPath = props.recordingPath;
    return (_jsxs(Stack, __assign({ direction: "row", sx: {
            justifyContent: "space-between",
            py: { xs: 2, md: 2 },
            px: { xs: 1, md: 2 },
            borderBottom: "1px solid",
            borderColor: "divider",
            backgroundColor: "background.body",
        } }, { children: [_jsx(Stack, __assign({ direction: "row", spacing: { xs: 1, md: 2 }, sx: { alignItems: "center" } }, { children: _jsx(Box, { children: _jsx(Typography, __assign({ component: "h2", noWrap: true, sx: { fontWeight: "lg", fontSize: "lg" } }, { children: sender.name })) }) })), recordingPath && (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(WaveformPlayer, { audioUrl: recordingPath }), _jsx(IconButton, __assign({ component: "a", href: recordingPath, download: true, sx: { ml: 1 } }, { children: _jsx(DownloadIcon, {}) }))] })))] })));
}
