var _a;
import { NODE_NAMES } from "../utils/nodeNameContants";
export var nodeConnectionRules = (_a = {},
    _a[NODE_NAMES.INBOUND_CALL] = {
        // "Inbound Call Node"
        // Only 1 outgoing, can connect to agent router or agent (AiAgentNode)
        allowedIncomingNodes: [],
        allowedOutgoingNodes: [NODE_NAMES.CALL_DISTRIBUTION_ROUTER, NODE_NAMES.AI_AGENT],
        maxIncoming: 0,
        maxOutgoing: 1,
    },
    _a[NODE_NAMES.CALL_DISTRIBUTION_ROUTER] = {
        // "Agent Router Node"
        // 1 incoming from Inbound Call,
        // Outgoing only to Agent (AiAgentNode)
        allowedIncomingNodes: [NODE_NAMES.INBOUND_CALL],
        allowedOutgoingNodes: [NODE_NAMES.AI_AGENT],
        maxIncoming: 1,
        maxOutgoing: Infinity,
    },
    _a[NODE_NAMES.AI_AGENT] = {
        // "Agent Node" 
        // Incoming from inbound call or agent router
        // Outgoing to buyer router or buyer
        allowedIncomingNodes: [NODE_NAMES.INBOUND_CALL, NODE_NAMES.CALL_DISTRIBUTION_ROUTER],
        allowedOutgoingNodes: [NODE_NAMES.BUYER_ROUTER, NODE_NAMES.BUYER],
        maxIncoming: 1,
        maxOutgoing: 1,
    },
    _a[NODE_NAMES.BUYER_ROUTER] = {
        // "Buyer Router Node"
        // Incoming from inbound phone call or AI agent
        // Outgoing to buyer node
        allowedIncomingNodes: [NODE_NAMES.INBOUND_CALL, NODE_NAMES.AI_AGENT],
        allowedOutgoingNodes: [NODE_NAMES.BUYER],
        maxOutgoing: Infinity,
        maxIncoming: Infinity,
    },
    _a[NODE_NAMES.BUYER] = {
        // "Buyer Node"
        // Incoming from Buyer Router, or Agent Node (AiAgentNode)
        // No outgoing
        allowedIncomingNodes: [NODE_NAMES.BUYER_ROUTER, NODE_NAMES.AI_AGENT],
        allowedOutgoingNodes: [],
        maxIncoming: Infinity,
        maxOutgoing: 0,
    },
    _a);
