var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position } from "@xyflow/react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Box, Select, Option } from "@mui/joy";
export default function AIAgentNode(_a) {
    var id = _a.id, data = _a.data;
    var agentId = data.agentId, agents = data.agents, onAIAgentChange = data.onAiAgentChange;
    var handleChange = function (newAgentId) {
        onAIAgentChange === null || onAIAgentChange === void 0 ? void 0 : onAIAgentChange(id, newAgentId);
    };
    return (_jsxs(Card, __assign({ variant: "soft", color: "success" }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(SmartToyIcon, {}) }, { children: "AI Agent" })), _jsx(Box, __assign({ mt: 1 }, { children: _jsx(Select, __assign({ size: "sm", value: agentId || "", onChange: function (_, val) { return val && handleChange(val); }, placeholder: "Select agent" }, { children: agents === null || agents === void 0 ? void 0 : agents.map(function (agent) { return (_jsx(Option, __assign({ value: agent.id }, { children: agent.name }), agent.id)); }) })) })), _jsx(Handle, { type: "target", position: Position.Top, style: {
                    width: 8,
                    height: 8,
                } }), _jsx(Handle, { type: "source", position: Position.Bottom, style: {
                    width: 8,
                    height: 8,
                } })] })));
}
