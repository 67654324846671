var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
export default function ChatBubble(props) {
    var content = props.content, variant = props.variant, timestamp = props.timestamp, sender = props.sender, logs = props.logs, transferedCallTranscript = props.transferedCallTranscript;
    var isSent = variant === "sent";
    var _a = useState(false), showLogs = _a[0], setShowLogs = _a[1];
    return (_jsxs(Box, __assign({ sx: {
            maxWidth: "60%",
            minWidth: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: isSent ? "flex-end" : "flex-start",
            mr: isSent ? "16px!important" : "0",
            position: "relative",
        } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { justifyContent: "space-between", mb: 0.25 } }, { children: [_jsx(Typography, __assign({ level: "body-xs" }, { children: sender.name })), _jsx(Typography, __assign({ level: "body-xs" }, { children: timestamp }))] })), _jsx(Sheet, __assign({ color: isSent ? "primary" : "neutral", variant: isSent ? "solid" : "soft", sx: {
                    p: 1.25,
                    borderRadius: "lg",
                    minWidth: "170px",
                    borderTopRightRadius: isSent ? 0 : "lg",
                    borderTopLeftRadius: isSent ? "lg" : 0,
                    backgroundColor: isSent
                        ? "var(--joy-palette-primary-solidBg)"
                        : "background.body",
                } }, { children: _jsx(Typography, __assign({ level: "body-sm", sx: {
                        color: isSent
                            ? "var(--joy-palette-common-white)"
                            : "var(--joy-palette-text-primary)",
                    } }, { children: content })) }))] })));
}
