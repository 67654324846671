var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//src\pages\CallFlows\components\FlowCanvas\NodePalette.tsx
import { useCallback } from "react";
import Sheet from "@mui/joy/Sheet";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import HubIcon from "@mui/icons-material/Hub";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { NODE_NAMES } from "./utils/nodeNameContants";
export default function NodePalette() {
    var onDragStart = useCallback(function (event, nodeType) {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    }, []);
    return (_jsxs(Sheet, __assign({ sx: {
            position: { xs: "fixed", md: "sticky" },
            zIndex: 10000,
            height: "100dvh",
            width: 240,
            top: 0,
            p: 2,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderRight: "1px solid",
            borderColor: "divider",
        } }, { children: [_jsx(Typography, __assign({ level: "title-lg", textColor: "text.secondary", component: "h1" }, { children: "Nodes" })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, "inboundCallNode"); }, variant: "soft", size: "sm", color: "primary", sx: { textAlign: "center", cursor: "grab", borderRadius: "sm" } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(PhoneIcon, {}) }, { children: "Inbound Call" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, NODE_NAMES.CALL_DISTRIBUTION_ROUTER); }, variant: "soft", size: "sm", color: "warning", sx: { textAlign: "center", cursor: "grab", borderRadius: "sm" } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(HubIcon, {}) }, { children: "Call Distribution Router" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, NODE_NAMES.AI_AGENT); }, variant: "soft", size: "sm", color: "success", sx: { textAlign: "center", cursor: "grab", borderRadius: "sm" } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(SmartToyIcon, {}) }, { children: "AI Agent" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, NODE_NAMES.BUYER_ROUTER); }, variant: "soft", color: "neutral", size: "sm", sx: { textAlign: "center", cursor: "grab", borderRadius: "sm" } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(AccountTreeIcon, {}) }, { children: "Buyer Router" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, NODE_NAMES.BUYER); }, variant: "soft", size: "sm", color: "danger", sx: { textAlign: "center", cursor: "grab", borderRadius: "sm" } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(PaidRoundedIcon, {}) }, { children: "Buyer" })) }))] })));
}
