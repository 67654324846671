var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// pages/ManageBuyers.tsx
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, Button, Stack, Typography, Dropdown, MenuButton, Menu, MenuItem, IconButton, Alert, Skeleton, } from "@mui/joy";
import MoreVert from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useColorScheme } from "@mui/joy/styles";
import { useBuyers } from "../../data/hooks/useBuyers";
import { GenerateLinkModal } from "./components/PaswordlessDashboardLinkCreation";
// --------------
// ActionDropdown
// --------------
var ActionDropdown = function (_a) {
    var buyer = _a.buyer, onDelete = _a.onDelete, onEdit = _a.onEdit, onGenerateLink = _a.onGenerateLink;
    return (_jsxs(Dropdown, { children: [_jsx(MenuButton, __assign({ slots: { root: IconButton }, slotProps: { root: { variant: "plain", color: "neutral" } } }, { children: _jsx(MoreVert, {}) })), _jsxs(Menu, __assign({ placement: "bottom-end", size: "sm" }, { children: [_jsxs(MenuItem, __assign({ onClick: function () { return onEdit(buyer); } }, { children: [_jsx(EditIcon, { sx: { mr: 1 } }), "Edit"] })), _jsxs(MenuItem, __assign({ onClick: function () { return onDelete(buyer); }, variant: "soft", color: "danger" }, { children: [_jsx(DeleteIcon, { sx: { mr: 1 } }), "Delete"] })), _jsxs(MenuItem, __assign({ onClick: function () { return onGenerateLink(buyer); } }, { children: [_jsx(LinkIcon, { sx: { mr: 1 } }), "Invite Buyer to Dashboard"] }))] }))] }));
};
export var ManageBuyers = function () {
    var navigate = useNavigate();
    var mode = useColorScheme().mode;
    var _a = useState(mode === "dark"), darkMode = _a[0], setDarkMode = _a[1];
    var _b = useBuyers(), buyers = _b.buyers, getAllBuyers = _b.getAllBuyers, deleteBuyer = _b.deleteBuyer;
    // For alerts / messages
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    // Loading state
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    // For modal
    var _f = useState(null), selectedBuyer = _f[0], setSelectedBuyer = _f[1];
    var _g = useState(false), modalOpen = _g[0], setModalOpen = _g[1];
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    // Fetch the buyers initially
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, getAllBuyers()];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    // ---------------------------
    // Handlers
    // ---------------------------
    var handleCreateNew = function () {
        // navigate to a separate route for creating a new buyer
        navigate("/buyers/create");
    };
    var handleEditBuyer = function (buyer) {
        // navigate to separate route for editing existing buyer
        navigate("/buyers/edit/".concat(buyer.id));
    };
    var handleDeleteBuyer = function (buyer) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm("Delete buyer '".concat(buyer.name, "'?")))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, deleteBuyer(buyer.id)];
                case 2:
                    _a.sent();
                    setSuccessMessage("Buyer \"".concat(buyer.name, "\" deleted successfully."));
                    return [4 /*yield*/, getAllBuyers()];
                case 3:
                    _a.sent(); // Refresh the list
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    setErrorMessage(error_1.message || "Failed to delete buyer.");
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleGenerateLink = function (buyer) {
        setSelectedBuyer(buyer);
        setModalOpen(true);
    };
    // ---------------------------
    // AG-GRID Definitions
    // ---------------------------
    var defaultColDef = useMemo(function () { return ({
        filter: true,
        resizable: true,
    }); }, []);
    var columnDefs = useMemo(function () { return [
        { headerName: "Name", field: "name", flex: 1 },
        { headerName: "Phone", field: "phoneNumber", flex: 1 },
        { headerName: "Lead Price", field: "leadPrice", flex: 1 },
        {
            headerName: "Actions",
            field: "actions",
            maxWidth: 120,
            sortable: false,
            filter: false,
            cellRenderer: function (params) {
                return (_jsx(Box, __assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "center",
                    } }, { children: _jsx(ActionDropdown, { buyer: params.data, onEdit: handleEditBuyer, onDelete: handleDeleteBuyer, onGenerateLink: handleGenerateLink }) })));
            },
        },
    ]; }, []);
    // ---------------------------
    // Render
    // ---------------------------
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: [_jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: "Manage Buyers" })), _jsx(Button, __assign({ startDecorator: _jsx(AddIcon, {}), onClick: handleCreateNew, variant: "solid", color: "primary", sx: { mt: "6" } }, { children: "Create New Buyer" }))] })), _jsxs(_Fragment, { children: [errorMessage && (_jsx(Alert, __assign({ variant: "soft", color: "danger", endDecorator: _jsx(Button, __assign({ variant: "plain", onClick: function () { return setErrorMessage(null); }, size: "sm" }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { mb: 2 } }, { children: errorMessage }))), successMessage && (_jsx(Alert, __assign({ variant: "soft", color: "success", endDecorator: _jsx(Button, __assign({ variant: "plain", onClick: function () { return setSuccessMessage(null); }, size: "sm" }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { mb: 2 } }, { children: successMessage })))] }), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: loading ? (
                // Show skeleton while loading
                _jsx(Box, __assign({ sx: {
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 2,
                        padding: 2,
                    } }, { children: __spreadArray([], Array(15), true).map(function (_, index) { return (_jsx(Box, __assign({ sx: { display: "flex", flexDirection: "column", gap: 1 } }, { children: _jsx(Skeleton, { variant: "rectangular", height: 30 }) }), index)); }) }))) : (_jsx(Box, __assign({ sx: { width: "100%", height: "100%" }, className: darkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz" }, { children: _jsx(AgGridReact, { rowData: buyers, columnDefs: columnDefs, defaultColDef: defaultColDef, pagination: true, enableCellTextSelection: true }) }))) })), _jsx(GenerateLinkModal, { open: modalOpen, onClose: function () { return setModalOpen(false); }, buyer: selectedBuyer })] })));
};
