// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the DateRange picker respects MUI Joy theme */
.custom-date-range-picker {
    background-color: var(--joy-palette-background-surface) !important;
    color: var(--joy-palette-text-primary) !important;
    border: 1px solid var(--joy-palette-divider) !important;
  }
  
  /* Update calendar background */
  .custom-date-range-picker .rdrCalendarWrapper,
  .custom-date-range-picker .rdrMonth {
    background-color: var(--joy-palette-background-surface) !important;
    color: var(--joy-palette-text-primary) !important;
  }
  
  /* Update selected date colors */
  .custom-date-range-picker .rdrSelected,
  .custom-date-range-picker .rdrInRange {
    background-color: var(--joy-palette-primary-softBg) !important;
  }
  
  /* Update text inside days */
  .custom-date-range-picker .rdrDayNumber span {
    color: var(--joy-palette-text-primary) !important;
  }
  
  /* Update button colors */
  .custom-date-range-picker .rdrNextPrevButton {
    background-color: var(--joy-palette-neutral-softBg) !important;
    color: var(--joy-palette-text-primary) !important;
  }
  
  .custom-date-range-picker .rdrNextPrevButton:hover {
    background-color: var(--joy-palette-neutral-solidBg) !important;
  }`, "",{"version":3,"sources":["webpack://./src/pages/BuyerDashboard/CallAnalytics/styles/styles.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;IACI,kEAAkE;IAClE,iDAAiD;IACjD,uDAAuD;EACzD;;EAEA,+BAA+B;EAC/B;;IAEE,kEAAkE;IAClE,iDAAiD;EACnD;;EAEA,gCAAgC;EAChC;;IAEE,8DAA8D;EAChE;;EAEA,4BAA4B;EAC5B;IACE,iDAAiD;EACnD;;EAEA,yBAAyB;EACzB;IACE,8DAA8D;IAC9D,iDAAiD;EACnD;;EAEA;IACE,+DAA+D;EACjE","sourcesContent":["/* Ensure the DateRange picker respects MUI Joy theme */\n.custom-date-range-picker {\n    background-color: var(--joy-palette-background-surface) !important;\n    color: var(--joy-palette-text-primary) !important;\n    border: 1px solid var(--joy-palette-divider) !important;\n  }\n  \n  /* Update calendar background */\n  .custom-date-range-picker .rdrCalendarWrapper,\n  .custom-date-range-picker .rdrMonth {\n    background-color: var(--joy-palette-background-surface) !important;\n    color: var(--joy-palette-text-primary) !important;\n  }\n  \n  /* Update selected date colors */\n  .custom-date-range-picker .rdrSelected,\n  .custom-date-range-picker .rdrInRange {\n    background-color: var(--joy-palette-primary-softBg) !important;\n  }\n  \n  /* Update text inside days */\n  .custom-date-range-picker .rdrDayNumber span {\n    color: var(--joy-palette-text-primary) !important;\n  }\n  \n  /* Update button colors */\n  .custom-date-range-picker .rdrNextPrevButton {\n    background-color: var(--joy-palette-neutral-softBg) !important;\n    color: var(--joy-palette-text-primary) !important;\n  }\n  \n  .custom-date-range-picker .rdrNextPrevButton:hover {\n    background-color: var(--joy-palette-neutral-solidBg) !important;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
