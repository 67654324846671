var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// pages/AddEditBuyer.tsx
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Stack, Typography, Divider, Sheet, Select, Option, FormControl, FormLabel, Input, Textarea, FormHelperText, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Avatar, } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useBuyers } from "../../../../data/hooks/useBuyers";
import TextMaskAdapter from "../../../../components/ui/MaskTextInput";
import NotificationSnackbars from "../../../../components/ui/NotificationSnackbars";
import TimePicker from "react-time-picker";
import FlagCircleRoundedIcon from "@mui/icons-material/FlagCircleRounded";
import { getTimeZones } from "@vvo/tzdb";
import "../../../../styles/timePickerStyles.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
// Simple lists of states/provinces
var US_STATES = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];
var CA_PROVINCES = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NS",
    "ON",
    "PE",
    "QC",
    "SK",
];
var timeZones = getTimeZones();
export var AddEditBuyer = function () {
    var buyerId = useParams().buyerId;
    var navigate = useNavigate();
    var isEditing = !!buyerId; // if there's a buyerId param, we're editing
    var _a = useBuyers(), getBuyerById = _a.getBuyerById, createBuyer = _a.createBuyer, updateBuyer = _a.updateBuyer, getAllBuyers = _a.getAllBuyers;
    var DEFAULT_TIME_ZONE = "America/Los_Angeles";
    // Form state
    var _b = useState({
        id: 0,
        name: "",
        description: "",
        phoneNumber: "",
        timeZone: DEFAULT_TIME_ZONE,
        leadPrice: 0,
        leadLockedDurationInSeconds: 15,
        supportedCountries: [],
        supportedStates: [],
        workingTimeSlots: [],
    }), buyerForm = _b[0], setBuyerForm = _b[1];
    // Alerts
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    // Load existing buyer if editing
    useEffect(function () {
        function loadBuyer(id) {
            return __awaiter(this, void 0, void 0, function () {
                var freshBuyer, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, getBuyerById(id)];
                        case 1:
                            freshBuyer = _a.sent();
                            if (freshBuyer) {
                                setBuyerForm(freshBuyer);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.error("Error loading buyer details:", error_1);
                            setErrorMessage("Error loading buyer details.");
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        if (isEditing && buyerId) {
            loadBuyer(parseInt(buyerId, 10));
        }
    }, [isEditing, buyerId]);
    // ===================== Handlers =====================
    var handleSaveBuyer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newId, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setErrorMessage(null);
                    setSuccessMessage(null);
                    // Basic validation
                    if (!buyerForm.name) {
                        setErrorMessage("Name is required.");
                        return [2 /*return*/];
                    }
                    if (!buyerForm.phoneNumber) {
                        setErrorMessage("Phone number is required.");
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    if (!!isEditing) return [3 /*break*/, 3];
                    return [4 /*yield*/, createBuyer(buyerForm)];
                case 2:
                    newId = _a.sent();
                    setSuccessMessage("Buyer #".concat(newId, " created successfully."));
                    return [3 /*break*/, 5];
                case 3: 
                // UPDATE
                return [4 /*yield*/, updateBuyer(Number(buyerId), buyerForm)];
                case 4:
                    // UPDATE
                    _a.sent();
                    setSuccessMessage("Buyer #".concat(buyerForm.id, " updated successfully."));
                    _a.label = 5;
                case 5: 
                // Refresh the list and navigate back
                return [4 /*yield*/, getAllBuyers()];
                case 6:
                    // Refresh the list and navigate back
                    _a.sent();
                    navigate("/buyers");
                    return [3 /*break*/, 8];
                case 7:
                    error_2 = _a.sent();
                    setErrorMessage(error_2.message || "Failed to save buyer.");
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    // ===================== WorkingTimeSlots Logic =====================
    var addTimeSlot = function () {
        setBuyerForm(function (prev) { return (__assign(__assign({}, prev), { workingTimeSlots: __spreadArray(__spreadArray([], prev.workingTimeSlots, true), [
                {
                    id: 0,
                    dayOfWeek: 1,
                    startTime: "09:00:00",
                    endTime: "17:00:00",
                },
            ], false) })); });
    };
    var removeTimeSlot = function (index) {
        setBuyerForm(function (prev) {
            var updatedSlots = __spreadArray([], prev.workingTimeSlots, true);
            updatedSlots.splice(index, 1);
            return __assign(__assign({}, prev), { workingTimeSlots: updatedSlots });
        });
    };
    var updateTimeSlotField = function (index, field, value) {
        setBuyerForm(function (prev) {
            var updatedSlots = __spreadArray([], prev.workingTimeSlots, true);
            updatedSlots[index][field] = value;
            return __assign(__assign({}, prev), { workingTimeSlots: updatedSlots });
        });
    };
    // ===================== Countries & States Logic =====================
    var toggleCountry = function (countryCode) {
        setBuyerForm(function (prev) {
            var hasCountry = prev.supportedCountries.includes(countryCode);
            var updatedCountries = __spreadArray([], prev.supportedCountries, true);
            if (hasCountry) {
                // Remove country
                updatedCountries = updatedCountries.filter(function (c) { return c !== countryCode; });
                // Remove states/provinces belonging to that country
                var updatedStates = __spreadArray([], prev.supportedStates, true);
                if (countryCode === "US") {
                    updatedStates = updatedStates.filter(function (s) { return !US_STATES.includes(s); });
                }
                else {
                    updatedStates = updatedStates.filter(function (s) { return !CA_PROVINCES.includes(s); });
                }
                return __assign(__assign({}, prev), { supportedCountries: updatedCountries, supportedStates: updatedStates });
            }
            else {
                // Add country
                updatedCountries.push(countryCode);
                return __assign(__assign({}, prev), { supportedCountries: updatedCountries });
            }
        });
    };
    var toggleStateOrProvince = function (code) {
        setBuyerForm(function (prev) {
            var hasCode = prev.supportedStates.includes(code);
            var updatedStates = __spreadArray([], prev.supportedStates, true);
            if (hasCode) {
                // Remove
                updatedStates = updatedStates.filter(function (c) { return c !== code; });
            }
            else {
                // Add
                updatedStates.push(code);
            }
            return __assign(__assign({}, prev), { supportedStates: updatedStates });
        });
    };
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            mx: "auto",
            p: 4,
            pt: 2,
        } }, { children: [_jsx(Typography, __assign({ level: "h2", component: "h1" }, { children: isEditing ? "Edit Buyer" : "Create Buyer" })), _jsx(NotificationSnackbars, { errorMessage: errorMessage, successMessage: successMessage, setErrorMessage: setErrorMessage, setSuccessMessage: setSuccessMessage }), _jsxs(Stack, __assign({ spacing: 2, direction: "row" }, { children: [_jsxs(FormControl, __assign({ sx: { flex: 1 } }, { children: [_jsx(FormLabel, { children: "Name" }), _jsx(Input, { placeholder: "Acme Plumbing Service", value: buyerForm.name, size: "sm", onChange: function (e) {
                                    return setBuyerForm(__assign(__assign({}, buyerForm), { name: e.target.value }));
                                } }), _jsx(FormHelperText, { children: "Enter the buyer\u2019s name." })] })), _jsxs(FormControl, __assign({ sx: { flex: 1 } }, { children: [_jsx(FormLabel, { children: "Phone Number" }), _jsx(Input, { value: buyerForm.phoneNumber, size: "sm", onChange: function (e) {
                                    return setBuyerForm(__assign(__assign({}, buyerForm), { phoneNumber: e.target.value }));
                                }, placeholder: "+1234567890", slotProps: { input: { component: TextMaskAdapter } } }), _jsx(FormHelperText, { children: "Enter a valid phone number." })] }))] })), _jsxs(Stack, __assign({ spacing: 2, direction: "row" }, { children: [_jsxs(FormControl, __assign({ sx: { flex: 1 } }, { children: [_jsx(FormLabel, { children: "Price per Lead" }), _jsx(Input, { type: "number", placeholder: "E.g. 25.50", size: "sm", value: buyerForm.leadPrice, startDecorator: "$", onChange: function (e) {
                                    return setBuyerForm(__assign(__assign({}, buyerForm), { leadPrice: parseFloat(e.target.value) || 0 }));
                                } }), _jsx(FormHelperText, { children: "Price for each generated lead." })] })), _jsxs(FormControl, __assign({ sx: { flex: 1 } }, { children: [_jsx(FormLabel, { children: "Lead Locked Duration (seconds)" }), _jsx(Input, { value: buyerForm.leadLockedDurationInSeconds, size: "sm", onChange: function (e) {
                                    return setBuyerForm(__assign(__assign({}, buyerForm), { leadLockedDurationInSeconds: parseInt(e.target.value) || 0 }));
                                } }), _jsx(FormHelperText, { children: "Lead is counted as Converted after X seconds." })] }))] })), _jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Description" }), _jsx(Textarea, { placeholder: "E.g. This buyer specializes in...", size: "sm", minRows: 3, value: buyerForm.description, onChange: function (e) {
                            return setBuyerForm(__assign(__assign({}, buyerForm), { description: e.target.value }));
                        } })] }), _jsx(Divider, {}), _jsx(Typography, __assign({ level: "title-sm" }, { children: "Supported Countries" })), _jsx(FormHelperText, { children: "Which countries does the buyer support?" }), _jsxs(Stack, __assign({ direction: "row", spacing: 2 }, { children: [_jsx(Checkbox, { size: "sm", label: "United States", checked: buyerForm.supportedCountries.includes("US"), onChange: function () { return toggleCountry("US"); } }), _jsx(Checkbox, { size: "sm", label: "Canada", checked: buyerForm.supportedCountries.includes("Canada"), onChange: function () { return toggleCountry("Canada"); } })] })), buyerForm.supportedCountries.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Typography, __assign({ level: "title-sm" }, { children: "Supported States/Provinces" })), _jsx(FormHelperText, { children: "Check the states or provinces within the selected country(ies) that the buyer supports." }), buyerForm.supportedCountries.includes("US") && (_jsxs(Accordion, __assign({ defaultExpanded: false }, { children: [_jsx(AccordionSummary, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1, sx: { width: "100%" } }, { children: [_jsx(Avatar, __assign({ color: "primary" }, { children: _jsx(FlagCircleRoundedIcon, {}) })), _jsx(Typography, __assign({ level: "body-sm", sx: { flexGrow: 1, textAlign: "left" } }, { children: "Supported US States" }))] })) }), _jsx(AccordionDetails, { children: _jsx(Sheet, __assign({ sx: {
                                        p: 1.5,
                                        borderRadius: "sm",
                                        mt: 1,
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 1,
                                    } }, { children: US_STATES.map(function (stateCode) { return (_jsx(Checkbox, { size: "sm", label: stateCode, checked: buyerForm.supportedStates.includes(stateCode), onChange: function () { return toggleStateOrProvince(stateCode); } }, stateCode)); }) })) })] }))), buyerForm.supportedCountries.includes("Canada") && (_jsxs(Accordion, __assign({ defaultExpanded: false }, { children: [_jsx(AccordionSummary, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1, sx: { width: "100%" } }, { children: [_jsx(Avatar, __assign({ color: "primary" }, { children: _jsx(FlagCircleRoundedIcon, {}) })), _jsx(Typography, __assign({ level: "body-sm", sx: { flexGrow: 1, textAlign: "left" } }, { children: "Supported Canadian Provinces" }))] })) }), _jsx(AccordionDetails, { children: _jsx(Sheet, __assign({ sx: {
                                        p: 1.5,
                                        borderRadius: "sm",
                                        mt: 1,
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 1,
                                    } }, { children: CA_PROVINCES.map(function (provinceCode) { return (_jsx(Checkbox, { size: "sm", label: provinceCode, checked: buyerForm.supportedStates.includes(provinceCode), onChange: function () { return toggleStateOrProvince(provinceCode); } }, provinceCode)); }) })) })] })))] })), _jsx(Divider, {}), _jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Time Zone" }), _jsx(Select, __assign({ size: "sm", value: buyerForm.timeZone, onChange: function (_, newVal) {
                            return setBuyerForm(function (prev) { return (__assign(__assign({}, prev), { timeZone: newVal || "" })); });
                        } }, { children: timeZones.map(function (tz) { return (_jsxs(Option, __assign({ value: tz.name }, { children: [tz.name, " ", tz.rawFormat] }), tz.abbreviation)); }) })), _jsx(FormHelperText, { children: "Select the buyer's time zone." })] }), _jsx(Divider, {}), _jsx(Typography, __assign({ level: "title-sm" }, { children: "Working Time Slots" })), _jsx(FormHelperText, { children: "Set day(s) and time range(s) for when the buyer can receive leads." }), _jsx(Button, __assign({ variant: "soft", size: "sm", startDecorator: _jsx(AddIcon, {}), onClick: addTimeSlot, sx: { alignSelf: "flex-start" } }, { children: "Add Time Slot" })), _jsxs(Sheet, __assign({ sx: {
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mb: 0,
                } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", sx: { pb: 1 } }, { children: [_jsx(Typography, __assign({ level: "title-sm", sx: { minWidth: 120 } }, { children: "Day of Week" })), _jsx(Typography, __assign({ level: "title-sm", sx: { minWidth: 120 } }, { children: "Start Time" })), _jsx(Typography, __assign({ level: "title-sm", sx: { minWidth: 120 } }, { children: " End Time" }))] })), buyerForm.workingTimeSlots.map(function (slot, index) { return (_jsx(Sheet, __assign({ sx: {
                            display: "flex",
                            flexDirection: "column",
                            mb: 0,
                        } }, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center" }, { children: [_jsx(FormControl, __assign({ sx: { minWidth: 120 } }, { children: _jsxs(Select, __assign({ size: "sm", value: slot.dayOfWeek.toString(), onChange: function (e, newVal) {
                                            return updateTimeSlotField(index, "dayOfWeek", Number(newVal));
                                        } }, { children: [_jsx(Option, __assign({ value: "0" }, { children: "Sunday" })), _jsx(Option, __assign({ value: "1" }, { children: "Monday" })), _jsx(Option, __assign({ value: "2" }, { children: "Tuesday" })), _jsx(Option, __assign({ value: "3" }, { children: "Wednesday" })), _jsx(Option, __assign({ value: "4" }, { children: "Thursday" })), _jsx(Option, __assign({ value: "5" }, { children: "Friday" })), _jsx(Option, __assign({ value: "6" }, { children: "Saturday" }))] })) })), _jsx(FormControl, { children: _jsx(TimePicker, { onChange: function (newTime) {
                                            if (newTime) {
                                                var _a = newTime.split(" "), time = _a[0], period = _a[1]; // Extract time and AM/PM
                                                var _b = time.split(":"), hh = _b[0], mm = _b[1];
                                                var hour = parseInt(hh, 10);
                                                var minute = parseInt(mm, 10);
                                                // Convert to 24-hour format
                                                if (period === "PM" && hour !== 12) {
                                                    hour += 12;
                                                }
                                                else if (period === "AM" && hour === 12) {
                                                    hour = 0;
                                                }
                                                var formattedTime = "".concat(hour.toString().padStart(2, "0"), ":").concat(minute.toString().padStart(2, "0"), ":00");
                                                console.log(formattedTime);
                                                updateTimeSlotField(index, "startTime", formattedTime);
                                            }
                                        }, amPmAriaLabel: "Select AM/PM", value: slot.startTime, className: "custom-time-picker", clearIcon: null }) }), _jsx(FormControl, { children: _jsx(TimePicker, { onChange: function (newTime) {
                                            if (newTime) {
                                                var _a = newTime.split(" "), time = _a[0], period = _a[1]; // Extract time and AM/PM
                                                var _b = time.split(":"), hh = _b[0], mm = _b[1];
                                                var hour = parseInt(hh, 10);
                                                var minute = parseInt(mm, 10);
                                                // Convert to 24-hour format
                                                if (period === "PM" && hour !== 12) {
                                                    hour += 12;
                                                }
                                                else if (period === "AM" && hour === 12) {
                                                    hour = 0;
                                                }
                                                var formattedTime = "".concat(hour.toString().padStart(2, "0"), ":").concat(minute.toString().padStart(2, "0"), ":00");
                                                console.log(formattedTime);
                                                updateTimeSlotField(index, "endTime", formattedTime);
                                            }
                                        }, amPmAriaLabel: "Select AM/PM", value: slot.endTime, className: "custom-time-picker", clearIcon: null }) }), _jsx(IconButton, __assign({ variant: "soft", color: "danger", size: "sm", onClick: function () { return removeTimeSlot(index); }, sx: { alignSelf: "center" } }, { children: _jsx(DeleteRoundedIcon, {}) }))] })) }), slot.id ? "slot-".concat(slot.id) : "new-slot-".concat(index))); })] })), _jsx(Divider, { sx: { mt: 2 } }), _jsxs(Stack, __assign({ direction: "row", justifyContent: "flex-end", spacing: 2, sx: { mb: 2 } }, { children: [_jsx(Button, __assign({ variant: "outlined", size: "sm", onClick: function () { return navigate("/buyers"); } }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "solid", color: "primary", onClick: handleSaveBuyer }, { children: isEditing ? "Update Buyer" : "Create Buyer" }))] }))] })));
};
