var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position } from "@xyflow/react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import HubIcon from "@mui/icons-material/Hub";
import Alert from "@mui/joy/Alert";
import { Box, Input } from "@mui/joy";
export default function RouterNode(_a) {
    var id = _a.id, data = _a.data;
    var _b = data.routes, routes = _b === void 0 ? [] : _b, routesError = data.routesError, updateNodeData = data.updateNodeData;
    var handlePercentageChange = function (event, targetNodeId) {
        var newVal = parseFloat(event.target.value) || 0;
        var updatedRoutes = routes.map(function (r) {
            return r.targetNodeId === targetNodeId ? __assign(__assign({}, r), { percentage: newVal }) : r;
        });
        var total = updatedRoutes.reduce(function (sum, r) { return sum + r.percentage; }, 0);
        var error = total !== 100 ? "Sum of route percentages must be exactly 100%" : "";
        updateNodeData === null || updateNodeData === void 0 ? void 0 : updateNodeData(id, {
            routes: updatedRoutes,
            routesError: error,
        });
    };
    return (_jsxs(Card, __assign({ variant: "soft", color: "warning", sx: { minWidth: 200, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(HubIcon, {}) }, { children: "Call Distribution Router" })), _jsx(Box, __assign({ mt: 1, sx: { fontSize: "0.9em" } }, { children: routes.length === 0 ? (_jsx(Typography, __assign({ sx: { fontStyle: "italic" } }, { children: "No connected AI Agents yet" }))) : (routes.map(function (route) { return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", mb: 1 } }, { children: [_jsx(Typography, __assign({ sx: { mr: 1 } }, { children: route.agentName })), _jsx(Input, { type: "number", size: "sm", value: route.percentage, onChange: function (e) { return handlePercentageChange(e, route.targetNodeId); }, sx: { width: 70, mr: 1 } }), _jsx(Typography, { children: "%" })] }), route.targetNodeId)); })) })), routesError && (_jsx(Alert, __assign({ variant: "soft", color: "danger", sx: { mt: 1 } }, { children: routesError }))), _jsx(Handle, { style: {
                    width: 8,
                    height: 8,
                }, type: "target", position: Position.Top }), _jsx(Handle, { style: {
                    width: 8,
                    height: 8,
                }, type: "source", position: Position.Bottom })] })));
}
