var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Tabs from "@mui/joy/Tabs";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import { useCallHistory } from "../../../../data/hooks/useCallHistory";
import Stack from "@mui/joy/Stack";
import MessagesPaneHeader from "../MessagesPaneHeader";
import AvatarWithStatus from "../AvatarWithStatus";
import ChatBubble from "../ChatBubble";
import { Divider } from "@mui/joy";
import { DetailedLogsGrid } from "../CheckpointsView";
export default function MessagesPane(props) {
    var _a, _b;
    var chat = props.chat;
    var _c = useCallHistory(), callCheckpoints = _c.callCheckpoints, callCheckpointsLoading = _c.callCheckpointsLoading, getCheckpointsBySessionId = _c.getCheckpointsBySessionId;
    var _d = React.useState("messages"), tabValue = _d[0], setTabValue = _d[1];
    var callTransferRendered = false; // Track if Call Transfer has been rendered
    React.useEffect(function () {
        // Optionally fetch checkpoints on first load so the data is ready
        if (tabValue === "checkpoints") {
            getCheckpointsBySessionId(chat.id);
        }
    }, [tabValue, chat.id]);
    return (_jsxs(Sheet, __assign({ sx: {
            height: { xs: "calc(100dvh - var(--Header-height))", md: "100dvh" },
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.level1",
        } }, { children: [_jsx(MessagesPaneHeader, { sender: chat.sender, recordingPath: chat.recordingPath }), _jsx(Box, __assign({ sx: {
                    display: "flex",
                    flex: 1,
                    minHeight: 0,
                    height: "100%",
                    overflowY: "scroll",
                    flexDirection: "column",
                } }, { children: _jsxs(Tabs, __assign({ value: tabValue, onChange: function (_, val) { return setTabValue(val); }, sx: { bgcolor: "transparent" } }, { children: [_jsxs(TabList, __assign({ tabFlex: 1, size: "sm", sx: (_a = {
                                    pl: { xs: 0, md: 4 },
                                    justifyContent: "left"
                                },
                                _a["&& .".concat(tabClasses.root)] = (_b = {
                                        fontWeight: "400",
                                        flex: "initial",
                                        color: "text.tertiary"
                                    },
                                    _b["&.".concat(tabClasses.selected)] = {
                                        bgcolor: "transparent",
                                        color: "text.primary",
                                        "&::after": {
                                            height: "2px",
                                            bgcolor: "primary.500",
                                        },
                                    },
                                    _b),
                                _a) }, { children: [" ", _jsx(Tab, __assign({ value: "messages" }, { children: "Messages" })), _jsx(Tab, __assign({ value: "checkpoints" }, { children: "Logs" }))] })), _jsx(TabPanel, __assign({ value: "messages" }, { children: _jsx(Stack, __assign({ spacing: 2, sx: { justifyContent: "flex-end" } }, { children: chat.messages.map(function (message, index) {
                                    var isUser = message.sender.name === "User";
                                    var isCallTransfer = message.transferedCallTranscript;
                                    // Insert "Call Transfer" placeholder only once before the first relevant message
                                    var showCallTransferPlaceholder = isCallTransfer && !callTransferRendered;
                                    if (isCallTransfer) {
                                        callTransferRendered = true; // Mark as rendered
                                    }
                                    return (_jsxs(_Fragment, { children: [showCallTransferPlaceholder && (_jsxs(Box, __assign({ sx: {
                                                    textAlign: "center",
                                                    my: 1,
                                                } }, { children: ["Call Transfered", _jsx(Divider, {})] }))), _jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { flexDirection: isUser ? "row-reverse" : "row" } }, { children: [!isCallTransfer ? (_jsx(AvatarWithStatus, { online: message.sender.online, src: message.sender.avatar, size: isUser ? "md" : "sm" })) : (_jsx(AvatarWithStatus, { online: message.sender.online, src: "", size: isUser ? "md" : "sm" })), _jsx(ChatBubble, __assign({ variant: isUser ? "sent" : "received" }, message, { content: message.content, sender: __assign(__assign({}, message.sender), { name: isCallTransfer
                                                                ? "Speaker"
                                                                : message.sender.name }) }))] }), index)] }));
                                }) })) })), _jsx(TabPanel, __assign({ value: "checkpoints", sx: { p: 2, height: "100%", width: "100%", overflowY: "auto" } }, { children: _jsx(DetailedLogsGrid, { logs: callCheckpoints, loading: callCheckpointsLoading }) }))] })) }))] })));
}
