var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Typography, Stack, } from "@mui/joy";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
// Re-usable helper to show +/– percentages with icon
function renderPercentage(value) {
    if (value === 0) {
        return (_jsx(Typography, __assign({ level: "body-xs", sx: { color: "neutral.500" } }, { children: "0.0%" })));
    }
    if (value > 0) {
        return (_jsxs(Typography, __assign({ level: "body-xs", sx: { color: "success.600", display: "flex" } }, { children: [_jsx(TrendingUpIcon, { fontSize: "small", sx: { mr: 0.3 } }), "".concat(value.toFixed(1), "%")] })));
    }
    // value < 0
    return (_jsxs(Typography, __assign({ level: "body-xs", sx: { color: "danger.600", display: "flex" } }, { children: [_jsx(TrendingDownIcon, { fontSize: "small", sx: { mr: 0.3 } }), "".concat(value.toFixed(1), "%")] })));
}
var BuyerPerformanceCards = function (_a) {
    var buyerPerformanceData = _a.buyerPerformanceData;
    if (!buyerPerformanceData || buyerPerformanceData.length === 0) {
        return _jsx(Typography, __assign({ level: "body-sm" }, { children: "No Buyer Performance data available." }));
    }
    return (_jsx(Stack, __assign({ direction: "row", flexWrap: "wrap", gap: 2 }, { children: buyerPerformanceData.map(function (buyer) { return (_jsx(Card, __assign({ variant: "outlined", sx: {
                borderrad: 0,
                mt: 2,
                minWidth: 300,
                maxWidth: 320,
                borderRadius: "0px",
                boxShadow: "sm",
            } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "h4", sx: { mb: 1 } }, { children: buyer.buyerName })), _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ level: "title-md" }, { children: "Revenue" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", gap: 1 }, { children: [_jsxs(Typography, __assign({ level: "title-md" }, { children: ["$", buyer.currentPeriod.revenue.toFixed(2)] })), renderPercentage(buyer.deltas.revenuePercentChange)] }))] })), _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ level: "body-sm" }, { children: "Total Transfered Calls" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", gap: 1 }, { children: [_jsx(Typography, __assign({ level: "body-md" }, { children: buyer.currentPeriod.totalCalls })), renderPercentage(buyer.deltas.totalCallsPercentChange)] }))] })), _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ level: "body-sm" }, { children: "Converted Calls" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", gap: 1 }, { children: [_jsx(Typography, __assign({ level: "body-md" }, { children: buyer.currentPeriod.convertedCallCount })), renderPercentage(buyer.deltas.convertedCallCountPercentChange)] }))] })), _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ level: "body-sm" }, { children: "Conversion Rate" })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", gap: 1 }, { children: [_jsxs(Typography, __assign({ level: "body-md" }, { children: [buyer.currentPeriod.conversionRate.toFixed(1), "%"] })), renderPercentage(buyer.deltas.conversionRatePercentChange)] }))] }))] }))] }) }), buyer.buyerId)); }) })));
};
export default BuyerPerformanceCards;
