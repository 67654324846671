var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Typography, Stack, Divider, FormControl, FormLabel, Grid, Select, Option, Checkbox, Input } from '@mui/joy';
import WebhookArgs from './components/WebhookArgs';
function Action(props) {
    var action = props.action, pluginIndex = props.pluginIndex, onPluginActionTypeChange = props.onPluginActionTypeChange, onPluginActionDescriptionChange = props.onPluginActionDescriptionChange, onPluginInjectResultChange = props.onPluginInjectResultChange, onPluginActionArgsPropertyChange = props.onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader = props.onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader = props.onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader = props.onPluginActionArgsDeleteHeader, onPluginActionArgsDeleteAdditionalBodyVariable = props.onPluginActionArgsDeleteAdditionalBodyVariable, onPluginActionArgsNewAdditionalBodyVariable = props.onPluginActionArgsNewAdditionalBodyVariable, onPluginActionArgsUpdateAdditionalBodyVariable = props.onPluginActionArgsUpdateAdditionalBodyVariable;
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Typography, __assign({ level: "title-sm" }, { children: "Action" })), _jsx(FormLabel, { children: "Description (Optional)" }), _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' } } }, { children: _jsx(Input, { size: "sm", placeholder: "Example: ALWAYS repeat all the provided values first and ask the caller to confirm before submitting.", value: action.actionDescription, onChange: function (event) { return onPluginActionDescriptionChange(pluginIndex, event.target.value); } }) })), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Specify the HTTP endpoint where you want to submit the collected data." })), _jsx(Divider, {}), _jsxs(Grid, __assign({ container: true, columnSpacing: 2, rowSpacing: 1, justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(Grid, { children: _jsx(FormLabel, { children: "Action Type" }) }), _jsx(Grid, { children: _jsx(FormControl, { children: _jsx(Select, __assign({ size: "sm", value: action.type, onChange: function (event, newValue) { return onPluginActionTypeChange(pluginIndex, newValue); } }, { children: _jsx(Option, __assign({ value: "webhook" }, { children: "HTTP Request" })) })) }) }), _jsx(Grid, { children: _jsx(FormControl, __assign({ sx: { flexDirection: "row" } }, { children: _jsx(Checkbox, { size: "sm", label: "Use the result in the next step", checked: action.injectResult, onChange: function (event) { return onPluginInjectResultChange(pluginIndex, event.target.checked); } }) })) })] })), action.type === "webhook" && action.argsObject &&
                _jsx(WebhookArgs, { args: action.argsObject, pluginIndex: pluginIndex, onPluginActionArgsPropertyChange: onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader: onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader: onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader: onPluginActionArgsDeleteHeader, onPluginActionArgsDeleteAdditionalBodyVariable: onPluginActionArgsDeleteAdditionalBodyVariable, onPluginActionArgsNewAdditionalBodyVariable: onPluginActionArgsNewAdditionalBodyVariable, onPluginActionArgsUpdateAdditionalBodyVariable: onPluginActionArgsUpdateAdditionalBodyVariable })] })));
}
export default memo(Action);
