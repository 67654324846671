var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { parseDetailedLogs } from "./parseDetailedLogs";
import { Box, Skeleton, Typography, useColorScheme, useTheme } from "@mui/joy";
export function DetailedLogsGrid(_a) {
    var logs = _a.logs, loading = _a.loading;
    var _b = useState([]), rowData = _b[0], setRowData = _b[1];
    var theme = useTheme(); // 🎨 Access MUI theme for colors
    var mode = useColorScheme().mode;
    var _c = useState(mode === "dark"), darkMode = _c[0], setDarkMode = _c[1];
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    // Parse logs as soon as they become available
    useEffect(function () {
        if (!loading && (logs === null || logs === void 0 ? void 0 : logs.length)) {
            setRowData(parseDetailedLogs(logs));
        }
        else {
            setRowData([]);
        }
    }, [logs, loading]);
    /** 🎨 Use theme-based colors for latency highlighting */
    function getLatencyCellStyle(latency) {
        if (latency === undefined)
            return {};
        if (latency > 3000) {
            return theme.palette.danger.solidBg; // 🔴 Red for >3s
        }
        else if (latency > 2000) {
            return theme.palette.warning.solidBg; // 🟠 Orange for 2-3s
        }
        else {
            return theme.palette.success.solidBg; // 🟢 Green for low latency
        }
    }
    var columnDefs = useMemo(function () { return [
        { headerName: "Time", field: "timeString", flex: 1, sortable: true },
        {
            headerName: "Delta (ms)",
            field: "deltaMs",
            flex: 1,
            cellRenderer: function (params) {
                var _a, _b;
                return (_jsx(Box, __assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                    } }, { children: _jsx(Typography, __assign({ level: "body-md", sx: {
                            color: getLatencyCellStyle((_a = params.data) === null || _a === void 0 ? void 0 : _a.deltaMs),
                        } }, { children: (_b = params.data) === null || _b === void 0 ? void 0 : _b.deltaMs })) })));
            },
        },
        {
            flex: 1,
            headerName: "Type",
            field: "logType",
            cellStyle: function (params) {
                return params.value === "CHECKPOINT"
                    ? { color: theme.palette.success.solidBg } // ✅ Use theme's green for Checkpoints
                    : {};
            },
        },
        { headerName: "Message", field: "message", flex: 4 },
    ]; }, [theme] // 🎨 Recalculate when theme changes
    );
    var defaultColDef = useMemo(function () {
        return {
            resizable: true,
            filter: true,
        };
    }, []);
    return (_jsx(Box, __assign({ sx: { width: "100%" } }, { children: loading ? (_jsx(Box, __assign({ sx: {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
                padding: 2,
            } }, { children: __spreadArray([], Array(15), true).map(function (_, index) { return (_jsx(Box, __assign({ sx: { display: "flex", flexDirection: "column", gap: 1 } }, { children: _jsx(Skeleton, { variant: "rectangular", height: 30 }) }), index)); }) }))) : (_jsx(Box, __assign({ className: darkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz", sx: { height: "calc(100vh - 200px)" } }, { children: _jsx(AgGridReact, { rowData: rowData, columnDefs: columnDefs, defaultColDef: defaultColDef, pagination: true, paginationPageSize: 100, domLayout: "autoHeight" // Adjust grid height dynamically
             }) }))) })));
}
