var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/components/GenerateLinkModal.tsx
import { useState } from "react";
import { Modal, ModalDialog, Typography, Alert, Button, Box, Input, FormLabel, } from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useWorkspace } from "../../../../data/hooks/useWorkspace";
export var GenerateLinkModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, buyer = _a.buyer;
    var inviteBuyer = useWorkspace().inviteBuyer;
    // Form state
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    // Alerts
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var handleInvite = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!buyer)
                        return [2 /*return*/];
                    setErrorMessage(null);
                    setSuccessMessage(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, inviteBuyer(email, buyer.id)];
                case 2:
                    response = _a.sent();
                    // The API returns UserInviteResponse: { email, status }
                    setSuccessMessage("Invite sent to: ".concat(email));
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    setErrorMessage(error_1.response.data || "Failed to send invite.");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Modal, __assign({ open: open, onClose: onClose, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ "aria-labelledby": "invite-buyer-title", sx: { minWidth: 400, maxWidth: 500 } }, { children: [_jsx(Typography, __assign({ id: "invite-buyer-title", level: "title-sm" }, { children: "Invite Buyer" })), errorMessage && (_jsx(Alert, __assign({ variant: "soft", color: "danger", endDecorator: _jsx(Button, __assign({ variant: "plain", size: "sm", onClick: function () { return setErrorMessage(null); } }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { my: 1 } }, { children: errorMessage }))), successMessage && (_jsx(Alert, __assign({ variant: "soft", color: "success", endDecorator: _jsx(Button, __assign({ variant: "plain", size: "sm", onClick: function () { return setSuccessMessage(null); } }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { my: 1 } }, { children: successMessage }))), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "column", gap: 1, mt: 1 } }, { children: [_jsx(FormLabel, { children: "Email:" }), _jsx(Input, { type: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, style: { padding: 8 } })] })), _jsxs(Box, __assign({ sx: { display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 } }, { children: [_jsx(Button, __assign({ variant: "plain", color: "neutral", onClick: onClose }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "solid", color: "primary", onClick: handleInvite }, { children: "Invite" }))] }))] })) })));
};
