var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//src\pages\CallFlows\components\FlowCanvas\FlowCanvas.tsx
import "@xyflow/react/dist/style.css";
import "../FlowCanvas/styles/styles.css";
import { useCallback, useEffect, useRef, useState, } from "react";
import { ReactFlow, addEdge, useNodesState, useEdgesState, useReactFlow, Controls, Background, BackgroundVariant, ReactFlowProvider, } from "@xyflow/react";
import { Box, Button, IconButton, Input, Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import NodePalette from "./NodePalette";
import { nodeTypes } from "./nodeTypes";
import AssignPhoneNumbersModal from "../AssignPhoneNumbersModal";
import { useCallFlows } from "../../../../data/hooks/useCallFlows";
import { useFlows } from "../../../../data/hooks/useFlows";
import { useBuyers } from "../../../../data/hooks/useBuyers";
import NotificationSnackbars from "../../../../components/ui/NotificationSnackbars";
import { isConnectionAllowed } from "./utils/checkConnection";
import { NODE_NAMES } from "./utils/nodeNameContants";
function isRouterNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === NODE_NAMES.CALL_DISTRIBUTION_ROUTER;
}
function isAiAgentNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === NODE_NAMES.AI_AGENT;
}
// NEW
function isBuyerNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === NODE_NAMES.BUYER;
}
function isBuyerRouterNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === NODE_NAMES.BUYER_ROUTER;
}
export default function FlowCanvas(_a) {
    var _this = this;
    var initialNodes = _a.initialNodes, initialEdges = _a.initialEdges, currentFlowName = _a.currentFlowName, onSave = _a.onSave, callFlowId = _a.callFlowId, initialAssignedNumbers = _a.initialAssignedNumbers;
    var screenToFlowPosition = useReactFlow().screenToFlowPosition;
    var navigate = useNavigate();
    var _b = useNodesState(initialNodes), nodes = _b[0], setNodes = _b[1], onNodesChange = _b[2];
    var _c = useEdgesState(initialEdges), edges = _c[0], setEdges = _c[1], onEdgesChange = _c[2];
    var _d = useState(currentFlowName), flowName = _d[0], setFlowName = _d[1];
    var _e = useState(null), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(null), successMessage = _f[0], setSuccessMessage = _f[1];
    // For phone assignment
    var _g = useState(false), assignModalOpen = _g[0], setAssignModalOpen = _g[1];
    var _h = useState(initialAssignedNumbers), assignedNumbers = _h[0], setAssignedNumbers = _h[1];
    var _j = useState([]), allNumbers = _j[0], setAllNumbers = _j[1];
    var _k = useState(false), isSaving = _k[0], setIsSaving = _k[1];
    // Refs for data
    var phoneNumbersRef = useRef([]);
    var aiAgentsRef = useRef([]);
    // NEW: store buyers
    var buyersRef = useRef([]);
    // Hooks
    var _l = useCallFlows(), getNotAssignedPhoneNumbers = _l.getNotAssignedPhoneNumbers, getAvailablePhoneNumbersForFlow = _l.getAvailablePhoneNumbersForFlow;
    var getFlows = useFlows().getFlows;
    var getAllBuyers = useBuyers().getAllBuyers; // for fetching Buyer list
    var handleBack = function () {
        navigate("/call-flows");
    };
    // ========== Data Fetching ==========
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var numbers, assignedNumbers_1, agents, buyers, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 7, , 8]);
                            numbers = [];
                            if (!callFlowId) return [3 /*break*/, 2];
                            return [4 /*yield*/, getAvailablePhoneNumbersForFlow(callFlowId)];
                        case 1:
                            numbers = _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, getNotAssignedPhoneNumbers()];
                        case 3:
                            numbers = _a.sent();
                            _a.label = 4;
                        case 4:
                            assignedNumbers_1 = numbers.filter(function (f) {
                                return initialAssignedNumbers.includes(f.id);
                            });
                            phoneNumbersRef.current = assignedNumbers_1 || [];
                            setAllNumbers(numbers || []);
                            return [4 /*yield*/, getFlows()];
                        case 5:
                            agents = _a.sent();
                            aiAgentsRef.current = agents || [];
                            return [4 /*yield*/, getAllBuyers()];
                        case 6:
                            buyers = _a.sent();
                            buyersRef.current = buyers || [];
                            // re-inject
                            injectCallbacksAndData();
                            return [3 /*break*/, 8];
                        case 7:
                            error_1 = _a.sent();
                            setErrorMessage("Failed to fetch phone numbers / agents / buyers");
                            return [3 /*break*/, 8];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, [callFlowId, initialAssignedNumbers]);
    // ========== Data Injection ==========
    var injectCallbacksAndData = useCallback(function () {
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                var newData = __assign(__assign({}, node.data), { updateNodeData: updateNodeData });
                if (node.type === NODE_NAMES.INBOUND_CALL) {
                    newData.phoneNumbers = phoneNumbersRef.current;
                }
                else if (node.type === NODE_NAMES.AI_AGENT) {
                    newData.agents = aiAgentsRef.current;
                    newData.onAiAgentChange = handleAiAgentChange;
                }
                else if (node.type === NODE_NAMES.CALL_DISTRIBUTION_ROUTER) {
                    newData.routes = newData.routes || [];
                    newData.routesError = "";
                }
                //Buyer Node data
                else if (node.type === NODE_NAMES.BUYER) {
                    newData.buyers = buyersRef.current;
                    newData.onBuyerChange = handleBuyerChange;
                }
                // Buyer Router
                else if (node.type === NODE_NAMES.BUYER_ROUTER) {
                    newData.routes = newData.routes || [];
                    newData.routesError = "";
                }
                return __assign(__assign({}, node), { data: newData });
            });
        });
    }, [setNodes]);
    useEffect(function () {
        injectCallbacksAndData();
    }, [initialNodes, injectCallbacksAndData]);
    // ========== Callbacks to update node data ==========
    var updateNodeData = useCallback(function (nodeId, updates) {
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (node.id === nodeId) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), updates) });
                }
                return node;
            });
        });
    }, [setNodes]);
    var handleAiAgentChange = useCallback(function (nodeId, newAgentId) {
        // 1) update the AI node's data
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (node.id === nodeId) {
                    var selectedAgent = aiAgentsRef.current.find(function (agent) { return agent.id === newAgentId; });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { agentId: newAgentId, agentName: (selectedAgent === null || selectedAgent === void 0 ? void 0 : selectedAgent.name) || "Unselected" }) });
                }
                return node;
            });
        });
        // 2) also update any router node referencing that AI node
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (isRouterNode(node)) {
                    var oldRoutes = node.data.routes || [];
                    var updatedRoutes = oldRoutes.map(function (r) {
                        var _a;
                        return r.targetNodeId === nodeId
                            ? __assign(__assign({}, r), { agentId: newAgentId, agentName: ((_a = aiAgentsRef.current.find(function (a) { return a.id === newAgentId; })) === null || _a === void 0 ? void 0 : _a.name) ||
                                    "Unselected" }) : r;
                    });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { routes: updatedRoutes }) });
                }
                return node;
            });
        });
    }, [setNodes]);
    // NEW: handle buyer selection
    var handleBuyerChange = useCallback(function (nodeId, newBuyerId) {
        // 1) update the Buyer node's data
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (node.id === nodeId) {
                    var selectedBuyer = buyersRef.current.find(function (b) { return b.id === newBuyerId; });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { buyerId: newBuyerId, buyerName: (selectedBuyer === null || selectedBuyer === void 0 ? void 0 : selectedBuyer.name) || "Unselected" }) });
                }
                return node;
            });
        });
        // 2) also update any BuyerRouter node referencing that Buyer node
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (isBuyerRouterNode(node)) {
                    var oldRoutes = node.data.routes || [];
                    var updatedRoutes = oldRoutes.map(function (r) {
                        var _a;
                        return r.targetNodeId === nodeId
                            ? __assign(__assign({}, r), { buyerId: newBuyerId, buyerName: ((_a = buyersRef.current.find(function (b) { return b.id === newBuyerId; })) === null || _a === void 0 ? void 0 : _a.name) ||
                                    "Unselected" }) : r;
                    });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { routes: updatedRoutes }) });
                }
                return node;
            });
        });
    }, [setNodes]);
    // ========== Connect / Edges ==========
    var onConnect = useCallback(function (connection) {
        var source = connection.source, target = connection.target;
        if (!source || !target)
            return;
        var sourceNode = nodes.find(function (n) { return n.id === source; });
        var targetNode = nodes.find(function (n) { return n.id === target; });
        // Ensure both nodes exist before checking connection rules
        if (!sourceNode || !targetNode) {
            setErrorMessage("Error: Missing source or target node.");
            return;
        }
        // Get connection validation result
        var validation = isConnectionAllowed(sourceNode, targetNode, edges);
        if (!validation.allowed) {
            setErrorMessage("Connection not allowed: ".concat(validation.reason));
            return;
        }
        // If router -> AI, add or update a route
        if (isRouterNode(sourceNode) && isAiAgentNode(targetNode)) {
            var selectedAgentId_1 = (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.agentId) || "";
            var selectedAgentName_1 = (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.agentName) || "Unselected";
            var routerRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
            var existingRoute = routerRoutes.find(function (r) { return r.targetNodeId === targetNode.id; });
            if (!existingRoute) {
                var newRoute = {
                    targetNodeId: targetNode.id,
                    agentId: selectedAgentId_1,
                    agentName: selectedAgentName_1,
                    percentage: 0,
                };
                updateNodeData(sourceNode.id, {
                    routes: __spreadArray(__spreadArray([], routerRoutes, true), [newRoute], false),
                });
            }
            else {
                // update existing
                var updatedRoutes = routerRoutes.map(function (r) {
                    return r.targetNodeId === targetNode.id
                        ? __assign(__assign({}, r), { agentId: selectedAgentId_1, agentName: selectedAgentName_1 }) : r;
                });
                updateNodeData(sourceNode.id, { routes: updatedRoutes });
            }
        }
        // NEW: If buyerRouterNode -> buyerNode
        if (isBuyerRouterNode(sourceNode) && isBuyerNode(targetNode)) {
            var selectedBuyerId_1 = (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.buyerId) || 0;
            var selectedBuyerName_1 = (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.buyerName) || "Unselected";
            var routerRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
            var existingRoute = routerRoutes.find(function (r) { return r.targetNodeId === targetNode.id; });
            if (!existingRoute) {
                var newRoute = {
                    targetNodeId: targetNode.id,
                    buyerId: selectedBuyerId_1,
                    buyerName: selectedBuyerName_1,
                    percentage: 0,
                };
                updateNodeData(sourceNode.id, {
                    routes: __spreadArray(__spreadArray([], routerRoutes, true), [newRoute], false),
                });
            }
            else {
                // update existing
                var updatedRoutes = routerRoutes.map(function (r) {
                    return r.targetNodeId === targetNode.id
                        ? __assign(__assign({}, r), { buyerId: selectedBuyerId_1, buyerName: selectedBuyerName_1 }) : r;
                });
                updateNodeData(sourceNode.id, { routes: updatedRoutes });
            }
        }
        // Finally, add the edge
        setEdges(function (eds) { return addEdge(connection, eds); });
    }, [nodes, edges, setEdges, updateNodeData]);
    var onEdgesDelete = useCallback(function (deletedEdges) {
        setEdges(function (prev) {
            var updated = __spreadArray([], prev, true);
            deletedEdges.forEach(function (edge) {
                updated = updated.filter(function (e) { return e.id !== edge.id; });
                var sourceNode = nodes.find(function (n) { return n.id === edge.source; });
                var targetNode = nodes.find(function (n) { return n.id === edge.target; });
                // If router -> AI is being disconnected, remove that route
                if (isRouterNode(sourceNode) && isAiAgentNode(targetNode)) {
                    var oldRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
                    var newRoutes = oldRoutes.filter(function (r) { return r.targetNodeId !== targetNode.id; });
                    updateNodeData(sourceNode.id, { routes: newRoutes });
                }
                // If buyerRouter -> buyerNode is being disconnected, remove that route
                if (isBuyerRouterNode(sourceNode) && isBuyerNode(targetNode)) {
                    var oldRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
                    var newRoutes = oldRoutes.filter(function (r) { return r.targetNodeId !== targetNode.id; });
                    updateNodeData(sourceNode.id, { routes: newRoutes });
                }
            });
            return updated;
        });
    }, [nodes, setEdges, updateNodeData]);
    // ========== Drag & Drop ==========
    var onDrop = useCallback(function (event) {
        event.preventDefault();
        var nodeType = event.dataTransfer.getData("application/reactflow");
        if (!nodeType)
            return;
        // e.g. only 1 inboundCallNode
        if (nodeType === NODE_NAMES.INBOUND_CALL) {
            var existing = nodes.find(function (n) { return n.type === NODE_NAMES.INBOUND_CALL; });
            if (existing) {
                setErrorMessage("Only one Inbound Call Node is allowed.");
                return;
            }
        }
        var position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
        // Default node data
        var newData = { updateNodeData: updateNodeData };
        if (nodeType === NODE_NAMES.INBOUND_CALL) {
            newData.phoneNumbers = phoneNumbersRef.current;
        }
        else if (nodeType === NODE_NAMES.AI_AGENT) {
            newData.agents = aiAgentsRef.current;
            newData.onAiAgentChange = handleAiAgentChange;
        }
        else if (nodeType === NODE_NAMES.CALL_DISTRIBUTION_ROUTER) {
            newData.routes = [];
            newData.routesError = "";
        }
        // NEW
        else if (nodeType === NODE_NAMES.BUYER) {
            newData.buyers = buyersRef.current;
            newData.onBuyerChange = handleBuyerChange;
        }
        else if (nodeType === NODE_NAMES.BUYER_ROUTER) {
            newData.routes = [];
            newData.routesError = "";
        }
        var newNode = {
            id: "".concat(nodeType, "-").concat(Date.now()),
            type: nodeType,
            position: position,
            data: newData,
        };
        setNodes(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newNode], false); });
    }, [
        nodes,
        screenToFlowPosition,
        handleAiAgentChange,
        handleBuyerChange,
        updateNodeData,
        setNodes,
        setErrorMessage,
    ]);
    var onDragOver = useCallback(function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);
    // ========== Save Logic ==========
    var handleSaveClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, onSave({ nodes: nodes, edges: edges, currentFlowName: flowName }, assignedNumbers)];
                case 2:
                    _a.sent();
                    setSuccessMessage("Flow saved successfully!");
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    setErrorMessage("Failed to save flow.");
                    return [3 /*break*/, 5];
                case 4:
                    setIsSaving(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // ========== Render ==========
    return (_jsxs(_Fragment, { children: [_jsx(NotificationSnackbars, { errorMessage: errorMessage, successMessage: successMessage, setErrorMessage: setErrorMessage, setSuccessMessage: setSuccessMessage }), _jsxs(Box, __assign({ sx: { display: "flex", height: "100vh" } }, { children: [_jsx(Box, { children: _jsx(NodePalette, {}) }), _jsxs(Box, __assign({ sx: { flex: 1, display: "flex", flexDirection: "column", minHeight: 0 } }, { children: [_jsx(Box, __assign({ sx: {
                                    position: "sticky",
                                    zIndex: 2,
                                    bgcolor: "background.body",
                                    borderBottom: "1px solid",
                                    borderColor: "divider",
                                    p: 2,
                                } }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Input, { placeholder: "Flow Name", value: flowName, size: "md", onChange: function (e) { return setFlowName(e.target.value); }, sx: { width: "100%", maxWidth: 400 } }), _jsxs(Box, __assign({ sx: { display: "flex", gap: 2 } }, { children: [_jsx(Button, __assign({ variant: "outlined", color: "success", onClick: function () { return setAssignModalOpen(true); } }, { children: "Assign Phone Numbers" })), _jsx(Button, __assign({ variant: "soft", loading: isSaving, onClick: handleSaveClick }, { children: "Save" })), _jsx(IconButton, __assign({ size: "sm", onClick: handleBack }, { children: "X" }))] }))] })) })), _jsx(AssignPhoneNumbersModal, { open: assignModalOpen, onClose: function () { return setAssignModalOpen(false); }, phoneNumbers: allNumbers, selectedNumbers: assignedNumbers, onSelectNumbers: function (selected) {
                                    setAssignedNumbers(selected);
                                } }), _jsx(Box, __assign({ sx: { flex: 1, minHeight: 0, position: "relative" } }, { children: _jsxs(ReactFlowProvider, { children: [_jsx(ReactFlow, { nodes: nodes, edges: edges, nodeTypes: nodeTypes, onNodesChange: onNodesChange, onEdgesChange: onEdgesChange, onConnect: onConnect, onEdgesDelete: onEdgesDelete, onDrop: onDrop, onDragOver: onDragOver, fitView: true, style: { width: "100%", height: "100%" } }), _jsx(Controls, {}), _jsx(Background, { variant: BackgroundVariant.Dots, gap: 16, size: 1 })] }) }))] }))] }))] }));
}
