var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//src\pages\CallAnalytics\index.tsx
import { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, Typography, Select, Stack, Input, FormLabel, useColorScheme, AccordionDetails, Accordion, AccordionSummary, AccordionGroup, } from "@mui/joy";
import Option from "@mui/joy/Option";
import Skeleton from "react-loading-skeleton";
import { Line, Pie, Chart } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement, } from "chart.js";
import { FunnelController, TrapezoidElement, } from "chartjs-chart-funnel";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FilterEnums } from "../../types/enums";
import { useCallAnalytics } from "../../data/hooks/useCallAnalytics";
import "../CallAnalytics/styles/styles.css";
import PerformanceCards from "./PerformanceCards";
import BuyerPerformanceCards from "./BuyerPerformanceCards";
ChartJS.register(Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement);
ChartJS.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);
// Helper to show something like "01/31/2025 - 02/02/2025" in the dropdown
var displayRange = function (start, end) {
    if (!start || !end)
        return "Custom Range";
    var s = start.toLocaleDateString();
    var e = end.toLocaleDateString();
    return "".concat(s, " - ").concat(e);
};
export var CallAnalytics = function () {
    var _a = useCallAnalytics(), 
    // Existing data & methods
    trendingChartData = _a.trendingChartData, isLoading = _a.isLoading, getTrendingChartData = _a.getTrendingChartData, funnelChartData = _a.funnelChartData, isLoadingFunnel = _a.isLoadingFunnel, getFunnelChartData = _a.getFunnelChartData, flows = _a.flows, getFlows = _a.getFlows, getCallEndReasonStats = _a.getCallEndReasonStats, endReasonStats = _a.endReasonStats, isLoadingEndReasonStats = _a.isLoadingEndReasonStats, getCardMetrics = _a.getCardMetrics, cardMetrics = _a.cardMetrics, isLoadingCardMetrics = _a.isLoadingCardMetrics, getBuyerTotalScorecards = _a.getBuyerTotalScorecards, buyerPerformanceData = _a.buyerPerformanceData, isLoadingBuyerPerformanceData = _a.isLoadingBuyerPerformanceData, 
    //Buyer Revenue Chart
    buyerRevenueChartData = _a.buyerRevenueChartData, isLoadingBuyerRevenueChartData = _a.isLoadingBuyerRevenueChartData, getBuyerRevenueChartData = _a.getBuyerRevenueChartData;
    var _b = useState(FilterEnums.last3Days), dateRange = _b[0], setDateRange = _b[1];
    var mode = useColorScheme().mode;
    var _c = useState("daily"), grouping = _c[0], setGrouping = _c[1];
    var _d = useState(undefined), selectedFlow = _d[0], setSelectedFlow = _d[1];
    var _e = useState(15), conversionCriteriaInSeconds = _e[0], setConversionCriteriaInSeconds = _e[1];
    // Custom date range
    var _f = useState(null), customStartDate = _f[0], setCustomStartDate = _f[1];
    var _g = useState(null), customEndDate = _g[0], setCustomEndDate = _g[1];
    var _h = useState(false), buyerSectionExpanded = _h[0], setBuyerSectionExpanded = _h[1];
    var _j = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]), selectedRange = _j[0], setSelectedRange = _j[1];
    // For showing/hiding <DateRange />
    var _k = useState(false), showDateRangePicker = _k[0], setShowDateRangePicker = _k[1];
    var dateRangeRef = useRef(null);
    // Hide date picker if user clicks outside
    useEffect(function () {
        function handleClickOutside(event) {
            if (dateRangeRef.current &&
                !dateRangeRef.current.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // Fetch available flows once
    useEffect(function () {
        getFlows();
    }, []);
    // Re-fetch trending data & card metrics on date-range changes
    useEffect(function () {
        getTrendingChartData(dateRange, grouping, selectedFlow, customStartDate, customEndDate);
        getCardMetrics(dateRange, selectedFlow, customStartDate, customEndDate);
    }, [dateRange, grouping, selectedFlow, customStartDate, customEndDate]);
    // Re-fetch funnel data
    useEffect(function () {
        getFunnelChartData(dateRange, grouping, conversionCriteriaInSeconds, selectedFlow, customStartDate, customEndDate);
    }, [
        dateRange,
        grouping,
        conversionCriteriaInSeconds,
        selectedFlow,
        customStartDate,
        customEndDate,
    ]);
    // Re-fetch buyer performance
    useEffect(function () {
        getBuyerTotalScorecards(dateRange, selectedFlow, customStartDate, customEndDate);
    }, [dateRange, selectedFlow, customStartDate, customEndDate]);
    // Re-fetch call end reason stats
    useEffect(function () {
        getCallEndReasonStats(dateRange, selectedFlow, customStartDate, customEndDate);
    }, [dateRange, selectedFlow, customStartDate, customEndDate]);
    //Re-fetch Buyer Revenue Chart
    useEffect(function () {
        getBuyerRevenueChartData(dateRange, grouping, customStartDate, customEndDate, selectedFlow);
    }, [dateRange, selectedFlow, grouping, customStartDate, customEndDate]);
    // Convert chart labels from UTC to local strings
    var adjustLabelsToLocalTime = function (labels) {
        return labels.map(function (utcString) {
            var date = new Date(utcString);
            switch (grouping) {
                case "hourly":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        hour12: true,
                    });
                case "daily":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                    });
                case "monthly":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        year: "numeric",
                    });
                default:
                    return date.toLocaleString(undefined, {
                        dateStyle: "short",
                        timeStyle: "short",
                    });
            }
        });
    };
    return (_jsx(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%", overflowY: "auto" } }, { children: _jsxs(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: [_jsx(Card, __assign({ sx: { marginBottom: 1 } }, { children: _jsx(CardContent, { children: _jsxs(Box, __assign({ display: "flex", gap: 1, flexWrap: "wrap" }, { children: [_jsxs(Select, __assign({ value: dateRange, onChange: function (event, newValue) {
                                        if (!newValue)
                                            return;
                                        setDateRange(newValue);
                                        if (newValue === FilterEnums.custom) {
                                            setShowDateRangePicker(true);
                                        }
                                        else {
                                            setShowDateRangePicker(false);
                                        }
                                    }, sx: { minWidth: 200 }, placeholder: "Select Date Range", size: "sm" }, { children: [_jsx(Option, __assign({ value: FilterEnums.last30Minutes }, { children: "Last 30 Minutes" })), _jsx(Option, __assign({ value: FilterEnums.last1Hour }, { children: "Last Hour" })), _jsx(Option, __assign({ value: FilterEnums.last4Hours }, { children: "Last 4 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last12Hours }, { children: "Last 12 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last24Hours }, { children: "Last 24 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last48Hours }, { children: "Last 48 Hours" })), _jsx(Option, __assign({ value: FilterEnums.today }, { children: "Today" })), _jsx(Option, __assign({ value: FilterEnums.yesterday }, { children: "Yesterday" })), _jsx(Option, __assign({ value: FilterEnums.last3Days }, { children: "Last 3 Days" })), _jsx(Option, __assign({ value: FilterEnums.last7Days }, { children: "Last 7 Days" })), _jsx(Option, __assign({ value: FilterEnums.last1Month }, { children: "Last 1 Month" })), _jsx(Option, __assign({ value: FilterEnums.last3Months }, { children: "Last 3 Months" })), _jsx(Option, __assign({ value: FilterEnums.all }, { children: "All" })), _jsx(Option, __assign({ value: FilterEnums.custom, onClick: function () {
                                                setDateRange(FilterEnums.custom);
                                                setShowDateRangePicker(true);
                                            } }, { children: displayRange(customStartDate, customEndDate) }))] })), _jsxs(Select, __assign({ size: "sm", value: grouping, onChange: function (e, newValue) { return setGrouping(newValue); }, placeholder: "Select Grouping", sx: { minWidth: 120 } }, { children: [_jsx(Option, __assign({ value: "hourly" }, { children: "Hourly" })), _jsx(Option, __assign({ value: "daily" }, { children: "Daily" })), _jsx(Option, __assign({ value: "monthly" }, { children: "Monthly" }))] })), _jsxs(Select, __assign({ size: "sm", value: selectedFlow || "", onChange: function (e, newValue) {
                                        return setSelectedFlow(newValue || undefined);
                                    }, placeholder: "Select Flow", sx: { minWidth: 150 } }, { children: [_jsx(Option, __assign({ value: "" }, { children: "All Agents" })), flows.map(function (flow) { return (_jsx(Option, __assign({ value: flow.flowId }, { children: flow.flowName }), flow.flowId)); })] }))] })) }) })), dateRange === FilterEnums.custom && showDateRangePicker && (_jsx(Box, __assign({ ref: dateRangeRef, sx: {
                        position: "absolute",
                        zIndex: 9999,
                        display: "inline-block",
                    } }, { children: _jsx(DateRange, { ranges: selectedRange, onChange: function (item) {
                            setSelectedRange([item.selection]);
                            setCustomStartDate(item.selection.startDate);
                            setCustomEndDate(item.selection.endDate);
                        }, moveRangeOnFirstSelection: false, editableDateInputs: true, className: mode === "dark" ? "custom-date-range-picker" : "" }) }))), _jsx(Card, __assign({ sx: { mb: 1 } }, { children: _jsx(CardContent, { children: isLoadingCardMetrics ? (_jsx(Skeleton, { height: 100 })) : cardMetrics ? (_jsx(PerformanceCards, { cardMetrics: cardMetrics })) : (_jsx(Typography, { children: "No data available" })) }) })), buyerPerformanceData && buyerPerformanceData.length > 0 && (_jsx(AccordionGroup, __assign({ variant: "outlined", size: "lg", sx: { mt: 1, borderRadius: "8px", overflow: "hidden" } }, { children: _jsxs(Accordion, __assign({ defaultExpanded: true }, { children: [_jsx(AccordionSummary, { children: _jsx(Typography, __assign({ level: "h3" }, { children: "Buyer Performance" })) }), _jsxs(AccordionDetails, { children: [isLoadingBuyerPerformanceData ? (_jsx(Skeleton, { height: 100 })) : (_jsx(BuyerPerformanceCards, { buyerPerformanceData: buyerPerformanceData })), _jsx(Typography, __assign({ level: "h3", sx: { mt: 2, mb: 1 } }, { children: "Revenue" })), isLoadingBuyerRevenueChartData ? (_jsx(Skeleton, { height: 400 })) : buyerRevenueChartData ? (_jsx(Box, __assign({ height: 400 }, { children: _jsx(Line, { data: {
                                                labels: adjustLabelsToLocalTime(buyerRevenueChartData.labels),
                                                datasets: buyerRevenueChartData.datasets.map(function (ds) { return (__assign(__assign({}, ds), { data: ds.data.map(function (val) { return parseFloat(val); }), fill: false, tension: 0.4, borderWidth: 2, pointRadius: 3 })); }),
                                            }, options: {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: { display: true },
                                                    tooltip: { mode: "index", intersect: false },
                                                },
                                                scales: {
                                                    x: { display: true },
                                                    y: { beginAtZero: true },
                                                },
                                            } }) }))) : (_jsx(Typography, { children: "No data available" }))] })] })) }))), _jsx(Card, __assign({ sx: { marginTop: 1 } }, { children: _jsx(CardContent, { children: isLoading ? (_jsx(Skeleton, { height: 400 })) : trendingChartData ? (_jsx(Box, __assign({ height: 400 }, { children: _jsx(Line, { data: {
                                    labels: adjustLabelsToLocalTime(trendingChartData.labels),
                                    datasets: trendingChartData.datasets.map(function (ds) { return (__assign(__assign({}, ds), { fill: false, tension: 0.4, borderWidth: 2, pointRadius: 4 })); }),
                                }, options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: { display: true },
                                        tooltip: { mode: "index", intersect: false },
                                    },
                                    scales: {
                                        x: { display: true },
                                        y: { beginAtZero: true },
                                    },
                                } }) }))) : (_jsx(Typography, { children: "No data available" })) }) })), _jsx(Card, __assign({ sx: {
                        marginTop: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    } }, { children: _jsxs(CardContent, __assign({ sx: { width: "100%" } }, { children: [_jsx(Typography, __assign({ level: "h3", sx: { mb: 2 } }, { children: "Call End Reasons" })), isLoadingEndReasonStats ? (_jsx(Skeleton, { height: 400 })) : endReasonStats ? (_jsx(Box, __assign({ sx: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                } }, { children: _jsx(Box, __assign({ sx: { width: "60%", maxWidth: 600, height: 600 } }, { children: _jsx(Pie, { data: {
                                            labels: endReasonStats.labels,
                                            datasets: endReasonStats.datasets.map(function (dataset) { return (__assign(__assign({}, dataset), { borderWidth: 2 })); }),
                                        }, options: {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { display: true, position: "top" },
                                                tooltip: { enabled: true },
                                                datalabels: {
                                                    formatter: function (value, context) {
                                                        var dataset = context.chart.data.datasets[0];
                                                        var total = dataset.data
                                                            .filter(function (val) { return typeof val === "number"; })
                                                            .reduce(function (acc, val) { return acc + val; }, 0);
                                                        var percentage = total > 0
                                                            ? ((value / total) * 100).toFixed(1) + "%"
                                                            : "0%";
                                                        return percentage;
                                                    },
                                                    color: "#fff",
                                                    font: { weight: "bold" },
                                                },
                                            },
                                        } }) })) }))) : (_jsx(Typography, { children: "No data available" }))] })) })), _jsx(Card, __assign({ sx: { marginTop: 1 } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "h3", sx: { mb: 2 } }, { children: "Call Conversion" })), _jsxs(Box, __assign({ display: "flex", gap: 1, sx: { marginTop: 1 } }, { children: [_jsx(FormLabel, { children: "Lead locked after seconds" }), _jsx(Input, { value: conversionCriteriaInSeconds, type: "number", onChange: function (event) {
                                            return setConversionCriteriaInSeconds(Number(event.target.value));
                                        } })] })), isLoadingFunnel ? (_jsx(Skeleton, { height: 400 })) : funnelChartData && funnelChartData.length > 0 ? (_jsx(Box, __assign({ sx: {
                                    display: "grid",
                                    gap: 2,
                                    gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "repeat(4, 1fr)",
                                    },
                                } }, { children: funnelChartData.map(function (item, index) {
                                    var dateObj = new Date(item.timePeriod);
                                    var localDateStr = dateObj.toLocaleString(undefined, {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                        hour: grouping === "hourly" ? "numeric" : undefined,
                                        hour12: grouping === "hourly",
                                    });
                                    var funnelData = {
                                        labels: ["Calls", "Transferred", "Converted"],
                                        datasets: [
                                            {
                                                label: "Funnel: ".concat(localDateStr),
                                                data: [
                                                    item.callCount,
                                                    item.transferredCount,
                                                    item.convertedCount,
                                                ],
                                                datalabels: {
                                                    formatter: function (v) { return v.toLocaleString(); },
                                                },
                                                backgroundColor: [
                                                    "rgba(255, 99, 132, 0.7)",
                                                    "rgba(54, 162, 235, 0.7)",
                                                    "rgba(75, 192, 75, 0.7)",
                                                ],
                                            },
                                        ],
                                    };
                                    var funnelOptions = {
                                        indexAxis: "y",
                                    };
                                    return (_jsxs(Box, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Typography, __assign({ level: "body-sm", sx: { mb: 1 } }, { children: localDateStr })), _jsx(Chart, { type: "funnel", data: funnelData, options: funnelOptions })] }), index));
                                }) }))) : (_jsx(Typography, { children: "No call conversion data available" }))] }) }))] })) })));
};
